import { Component, Input, OnInit } from '@angular/core';
import { EntityType } from '../../../createShipment/models/enums/entity-type.enum';
import { ClearanceDetails } from '../../../supplier/models/clearance-details';
import { SupplierHelperService } from '../../../supplier/services/supplier-helper.service';

@Component({
  selector: 'app-customs-clearance-pricelist-details-summary',
  templateUrl: './customs-clearance-pricelist-details-summary.component.html',
  styleUrls: ['./customs-clearance-pricelist-details-summary.component.scss']
})
export class CustomsClearancePricelistDetailsSummaryComponent
  implements OnInit {
  @Input() clearanceDetails: ClearanceDetails;
  @Input() suplierName: string;
  @Input() isCustomerCustomesClearance: boolean;

  constructor(private supplierHelperService: SupplierHelperService) {}

  ngOnInit(): void {
  }

  get ports() {
    if (this.clearanceDetails?.isAllPortsSelected) {
      return 'All Ports';
    } else {
      return this.clearanceDetails?.portsOfClearance
        .map(port => port.code)
        .toString()
        .replace(new RegExp(',', 'g'), ', ');
    }
  }
  get loadtypes() {
    return this.clearanceDetails?.loadTypes.name;
  }
  get containerTypes() {
    return this.clearanceDetails?.containerTypes
      ?.map(containerTypes => containerTypes.name)
      .toString()
      .replace(new RegExp(',', 'g'), ', ');
  }
  get transactionTypes() {
    return this.clearanceDetails?.transactionTypes
      .toString()
      .replace(new RegExp(',', 'g'), ', ');
  }
  get isAirFreight(): boolean {
    return this.supplierHelperService.isAirFreight(
      this.clearanceDetails?.freightType
    );
  }
  get isLandFreight(): boolean {
    return this.supplierHelperService.isLandFreight(
      this.clearanceDetails?.freightType
    );
  }
  get isBreakbulkLoadType(): boolean {
    return this.supplierHelperService.isBreakbulkLoadType(
      this.clearanceDetails?.loadTypes?.name
    );
  }
}
