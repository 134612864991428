import { Injectable } from '@angular/core';
import { ApprovalBase } from '../models/approval-base.model';
import { ApprovalStatus } from 'src/app/admin-portal-configs/models/approval-status.model';
import { ApprovalColumns } from '../enums/approval-columns';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { BaseNamedEntity } from 'src/app/shared/models/shipment/base-named-entity.model';
import { CancelationDetails } from 'src/app/shared/models/cancelation-details';

@Injectable({
  providedIn: 'root'
})
export class ApprovalHelperService {
  constructor(private localStorage: LocalStoreManager) {}

  clearEmployeeApprovalListView() {
    this.localStorage.deleteData('selectedApprovalListView');
  }

  getEmployeeApprovalListView(): string {
    return this.localStorage.getData('selectedApprovalListView');
  }

  saveEmployeeApprovalListView(selectedCardView: string) {
    this.localStorage.saveSyncedSessionData(
      selectedCardView,
      'selectedApprovalListView'
    );
  }

  getApprovalActivity(approval: ApprovalBase): BaseNamedEntity {
    return approval?.approvalActivity;
  }

  getApprovalType(approval: ApprovalBase): string {
    return approval?.approvalType?.name;
  }

  getCreatedBy(approval: ApprovalBase): string {
    return approval?.createdBy?.fullName;
  }

  getApprovalNumber(approval: ApprovalBase): string {
    return approval?.freePLApprovalNumberString;
  }

  getCreatedAt(approval: ApprovalBase): Date {
    return approval?.createdAt;
  }

  getUpdatedOn(approval: ApprovalBase): Date {
    return approval?.updatedOn;
  }

  getStatus(approval: ApprovalBase): ApprovalStatus {
    return approval?.status;
  }

  getReasonName(approval: ApprovalBase): string {
    return approval?.approvalTypeReason?.name;
  }

  getCategoryClassification(approval: ApprovalBase): string {
    return approval?.categoryClassification;
  }

  getColumnContent(columnName: string, approval: ApprovalBase): string | Date {
    switch (columnName) {
      case ApprovalColumns.Status:
        return this.getStatus(approval)?.name;
      case ApprovalColumns.ApprovalReason:
        return this.getReasonName(approval);
      case ApprovalColumns.ApprovalNumber:
        return this.getApprovalNumber(approval);
      case ApprovalColumns.ApprovalType:
        return this.getApprovalType(approval);
      case ApprovalColumns.CategoryClassification:
        return this.getCategoryClassification(approval);
      case ApprovalColumns.Created:
        return this.getCreatedAt(approval);
    }
  }

  getStatusColor(approval: ApprovalBase) {
    return this.getStatus(approval)?.colorCode;
  }

  getCancelationDetails(approval: ApprovalBase):CancelationDetails{
    return approval?.cancelationDetails;
  }
}
