import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { CancelEntityComponent } from 'src/app/shared/components/cancel-entity/cancel-entity.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PauseProductRegistrationPopupComponent } from '../../components/pause-product-registration-popup/pause-product-registration-popup.component';
import { ResumeProductRegistrationPopupComponent } from '../../components/resume-product-registration-popup/resume-product-registration-popup.component';
import { ProductRegistrationDetails } from '../../models/product-registration-details.model';
import { ProductRegistrationService } from '../../services/product-registration.service';
import { ProductRegistrationCommentWithActionActors } from '../../models/product-registration-comment-with-action-actors.model';
import { ProductRegistrationCommentService } from '../../services/product-registration-comment.service';
import { ProductRegistrationsActivityCategoriesEnum } from 'src/app/admin-portal-configs/enums/product-registrations-activity-categories.enum';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ProductRegistrationActivity } from '../../enums/product-registration-activity.enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';

@Component({
  selector: 'app-product-registration-details',
  templateUrl: './product-registration-details.component.html',
  styleUrls: ['./product-registration-details.component.scss']
})
export class ProductRegistrationDetailsComponent implements OnInit {
  productRegistrationDetails: ProductRegistrationDetails;
  productRegistrationCommentWithActionActors: ProductRegistrationCommentWithActionActors[] = [];
  newProductRegistrationCommentWithActionActor: ProductRegistrationCommentWithActionActors;
  productRegistrationModuleName = ModuleName.Registration;
  nextActivities: any[] = [];
  isLoading: boolean;
  productRegistrationEntity = EntityType.Registration;

  constructor(
    private route: ActivatedRoute,
    private productRegistrationService: ProductRegistrationService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private registrationActivityService: RegistrationActivityService,
    private authService: AuthService,
    private productRegistrationCommentService: ProductRegistrationCommentService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.productRegistrationDetails = data['details'];
      this.productRegistrationCommentWithActionActors = data['comments'];
    });
    this.isLoading = true;
    this.getNextActivities();

    this.productRegistrationService.productRegistrationRequestUpdated.subscribe(
      () => {
        this.getProductRegistrationDetails();
      }
    );

    this.productRegistrationService.productLeadTimeNotesUpdated.subscribe(x => {
      this.updateLeadTimeNotes(x?.leadTimeId, x?.leadTimeNotes);
    });

    this.productRegistrationCommentService.onCommentsUpdate.subscribe(data => {
      if (data) {
        this.newProductRegistrationCommentWithActionActor = data;
      } else {
        this.getCommentsByProductId();
      }
    });
  }

  getNextActivities(): void {
    this.registrationActivityService
      .getByName(
        this.productRegistrationDetails?.productRegistration
          ?.lastActivityLogViewModel?.activityName
      )
      .subscribe(res => {
        this.nextActivities = res.nextActivities.filter(
          activity =>
            activity.name !==
              ProductRegistrationsActivityCategoriesEnum.Cancelled &&
            activity.name !== ProductRegistrationsActivityCategoriesEnum.OnHold
        );
        this.isLoading = false;
      });
  }

  getProductRegistrationDetails(): void {
    this.productRegistrationService
      .getProductRegistrationDetails(
        this.productRegistrationDetails?.productRegistration?.id
      )
      .subscribe(res => {
        this.productRegistrationDetails = res;
      });
  }

  updateLeadTimeNotes(leadTimeId: number, leadTimeNotes: string): void {
    let oldLeadTime = this.productRegistrationDetails?.productLeadTimeLogs?.find(
      l => l.id === leadTimeId
    );
    if (oldLeadTime) {
      oldLeadTime.notes = leadTimeNotes;
    }
  }

  cancelRequest(): void {
    this.dialogService.open(
      CancelEntityComponent,
      {
        entityName: EntityType.Registration,
        parentId: this.productRegistrationDetails.productRegistration?.id
      },
      '600px'
    );
  }

  pauseResumeRequest(): void {
    if (!this.isProductPaused) {
      this.dialogService.open(
        PauseProductRegistrationPopupComponent,
        {
          productId: this.productRegistrationDetails.productRegistration?.id
        },
        '650px'
      );
    } else {
      this.dialogService.open(
        ResumeProductRegistrationPopupComponent,
        {
          productId: this.productRegistrationDetails.productRegistration?.id
        },
        '600px'
      );
    }
  }

  private getCommentsByProductId(): void {
    this.productRegistrationCommentService
      .getProductRegistrationComment(this.productId)
      .subscribe(response => {
        this.productRegistrationCommentWithActionActors = response;
      });
  }

  get isCustomer(): boolean {
    return this.authService.isCustomer;
  }

  get productId(): string {
    return this.productRegistrationHelperService.getProductId(
      this.productRegistrationDetails.productRegistration
    );
  }

  get productRegistration(): ProductRegistration {
    return this.productRegistrationDetails?.productRegistration;
  }

  get isProductPaused(): boolean {
    return this.productRegistrationHelperService.isPausedStatus(
      this.productRegistration
    );
  }

  get canPauseResumeProductRegistration(): boolean {
    return this.authService.canPauseResumeProductRegistration;
  }

  get canViewActivityLogTab(): boolean {
    return this.authService.canViewActivityLogTab;
  }
  get canViewLeadTimesTab(): boolean {
    return this.authService.canViewLeadTimesTab;
  }
  get canViewOverviewTab(): boolean {
    return this.authService.canViewOverviewTab;
  }
  get canViewProductsTab(): boolean {
    return this.authService.canViewProductsTab;
  }

  get canEditProductRegistration(): boolean {
    return this.authService.canEditProductRegistration;
  }

  get currentProgressPointIndex(): number {
    return this.productRegistrationDetails?.progressPoints?.findIndex(
      item => item.name === this.currentProgressPointName
    );
  }

  get currentProgressPointName(): string {
    return this.productRegistrationHelperService.getCurrentProgressPointName(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get hasPendingActions(): boolean {
    return this.productRegistrationHelperService.getHasPendingActions(
      this.productRegistration
    );
  }

  get isEditDetailsDisabled(): boolean {
    let isStatusReached =
      this.productRegistrationDetails?.progressAndActivityLogs?.findIndex(
        p =>
          p.activityLogs.findIndex(
            a =>
              a?.registrationActivity?.name ===
                ProductRegistrationActivity.SubmittedToEDA ||
              a?.registrationActivity?.name ===
                ProductRegistrationActivity.SCApprovalRequired
          ) != -1
      ) != -1;

    return isStatusReached && this.isCustomer;
  }
}
