<div class="old-version-container">
  <div class="older-version-row">
    <div class="older-version col-title">Older Versions</div>
  </div>

  <div
    class="older-version-row"
    *ngFor="let version of oldVersions; let index = index"
  >
    <div class="equivalent-space-for-document-type"></div>
    <div class="older-version file">
      <div
        class="file-name cursor-pointer"
        [ngClass]="{ 'deleted-file-name': version?.isDeleted }"
        (click)="viewFile(version)"
      >
        {{ version.fileName }}
      </div>
      <div class="file-upload-date">
        {{ (version?.createdAt ?? version?.uploadDateTime) | date: "dd/MM/YYYY hh:mm a" }}
        - {{ version?.uploadedBy?.fullName }},
        {{ version?.uploadedBy?.companyName }}
      </div>
      <div class="document-deleted" *ngIf="version.isDeleted">
        <div class="deleted-text">Deleted</div>
        <span
          [placement]="
            index == oldVersions?.length - 1 ? 'top-left' : 'bottom-left'
          "
          [ngbPopover]="deletionReasonTemplate"
          triggers="mouseenter:mouseleave"
          ><svg-icon [name]="'red-info'"></svg-icon
        ></span>
      </div>
    </div>
    <div class="actions" [class]="version.isDeleted ? 'disabled' : ''">
      <span class="action-btn download-btn" (click)="download(version)">
        <svg-icon name="download"></svg-icon>
      </span>

      <span class="action-btn download-btn replace-btn">
        <svg-icon name="replaceDocument"></svg-icon>
      </span>

      <span class="action-btn delete-btn" (click)="deleteDocument(version)">
        <svg-icon name="shipmentDetailsActionDelete"></svg-icon>
      </span>
      <ng-template #deletionReasonTemplate>
        <div class="deletion-popOver">
          <div class="deletion-reason-con row">
            <div class="reason-title col-4 pl-0">Deleted on</div>
            <div class="reason-info col-8 px-0">
              {{ version.deletedAt | date: "dd/MM/YYYY hh:mm a" }}
            </div>
          </div>
          <div class="deletion-reason-con row">
            <div class="reason-title col-4 pl-0">Deleted by</div>
            <div class="reason-info col-8 px-0">
              {{ version.deletedBy?.fullName }},
              {{ version.deletedBy?.companyName }}
            </div>
          </div>
          <div class="deletion-reason-con row">
            <div class="reason-title col-4 pl-0">Reason</div>
            <div class="deletion-reason reason-info col-8 px-0">
              {{ version.deletionReason }}
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="equivalent-space-for-exapnsion-arrow"></div>
  </div>
</div>
