<div class="truck-details-container">
  <div class="header d-flex">
    <div class="field text-label">No.</div>
    <div class="field text-label">Load Type<span class="required">*</span></div>
    <div class="field text-label">
      Truck Type<span class="required">*</span>
    </div>
    <div class="field text-label">
      Truck Sub-type<span class="required">*</span>
    </div>
    <div class="field text-label" *ngIf="!isForCustomerCompany">
      Transport Price<span class="required">*</span>
    </div>
  </div>

  <div
    *ngFor="let truckDetails of truckDetailsFormArray.controls; let i = index"
  >
    <div
      [formGroup]="truckDetails"
      class="d-flex inputs"
      *ngIf="truckDetails?.get('isDeleted')?.value != true"
    >
      <div class="field no-field">{{ i + 1 }}</div>

      <ng-select
        class="field"
        [clearable]="false"
        [ngClass]="isInvalid('loadType', i) ? 'error' : ''"
        [items]="lookups?.loadTypes"
        bindLabel="name"
        formControlName="loadType"
        (change)="onChangeLoadType($event, i)"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="d-flex">
            <div class="circle"></div>
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>
      <ng-select
        class="field"
        [clearable]="false"
        [ngClass]="isInvalid('truckType', i) ? 'error' : ''"
        [items]="compatibleTruckTypes[i]"
        bindLabel="name"
        formControlName="truckType"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="d-flex">
            <div class="circle"></div>
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>
      <ng-select
        class="field"
        [clearable]="false"
        [ngClass]="isInvalid('truckSubType', i) ? 'error' : ''"
        [items]="lookups?.truckSubTypes"
        bindLabel="name"
        formControlName="truckSubType"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="d-flex">
            <div class="circle"></div>
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>

      <div class="combined-fields d-flex" *ngIf="!isForCustomerCompany">
        <input
          placeholder="0.00"
          class="rate"
          formControlName="transportPrice"
          mask="0*.00*"
          [ngClass]="isInvalid('transportPrice', i) ? 'error' : ''"
          [dropSpecialCharacters]="false"
          appThousandSeparator
        />

        <ng-select
          class="field mr-0"
          [clearable]="false"
          [ngClass]="isInvalid('currency', i) ? 'error' : ''"
          [items]="lookups?.currencies"
          bindLabel="code"
          formControlName="currency"
        >
        </ng-select>
      </div>

      <button
        class="btn p-0"
        *ngIf="!isEnableDeleteRoute && !isForCustomerCompany"
      >
        <svg-icon name="gray-delete" class="delete-icon"></svg-icon>
      </button>
      <button
        class="btn p-0"
        (click)="deleteTruckDetails(i)"
        *ngIf="isEnableDeleteRoute && !isForCustomerCompany"
      >
        <svg-icon name="delete" class="delete-icon"></svg-icon>
      </button>
    </div>
  </div>
  <button
    class="btn add-btn underlined"
    (click)="addTruckDetails(true)"
    *ngIf="!isForCustomerCompany"
  >
    + Add another load type
  </button>
</div>
