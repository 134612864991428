<div class="headers">
  <div class="label">{{ entityName }} Charge</div>
  <div class="label">Rate</div>
  <div class="label">Quantity</div>
  <div class="label">Total Charge</div>
</div>
<div class="charges-content" *ngFor="let charge of charges">
  <div class="label">{{ charge?.chargeTypeName }}</div>
  <div class="label">{{ charge?.rate }} {{ charge?.currencyCode }}</div>
  <div class="label">{{ charge?.quantity }}</div>
  <div class="label">{{ charge?.amount }} {{ charge?.currencyCode }}</div>
</div>
