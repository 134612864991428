import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplierLookups } from 'src/app/company/models/supplier-lookups.models';
import { Supplier } from 'src/app/company/models/supplier.model';
import { environment } from 'src/environments/environment';
import { SupplierTermsAndConditions } from '../models/supplier-terms-and-conditions.model';
import { TruckingPricelistLookups } from '../models/trucking-pricelist-lookups.model';
import { TruckingPricelist } from '../models/trucking-pricelist.model';
import { CustomsCleranceLookUps } from '../models/customs-clerance-lookUps';
import { CustomsClearancePriceList } from '../models/customs-clearance-priceList';
import { TruckingDetailsRouteSearchCriteries } from '../models/trucking-details-route-search-criteries.model';
import { Paginated } from '../../shared/models/shipment/paginated.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  baseUrl = environment.apiUrl + 'Supplier';

  supplier: Supplier;
  @Output() supplierUpdated = new EventEmitter<Supplier>();

  constructor(private http: HttpClient) {}

  getSupplierById(id: string): Observable<Supplier> {
    return this.http.get<Supplier>(this.baseUrl + `/${id}`);
  }

  getSupplierLookups(): Observable<SupplierLookups> {
    return this.http.get<SupplierLookups>(this.baseUrl + `/Lookups`);
  }

  submitSupplierDetails(supplier: Supplier): Observable<Supplier> {
    return this.http.post<Supplier>(this.baseUrl + `/SubmitSupplier`, supplier);
  }

  updateSupplierTerms(supplierId: string, terms: string): Observable<Supplier> {
    let termsAndConditions: SupplierTermsAndConditions = {
      content: terms
    };
    return this.http.put<Supplier>(
      this.baseUrl + `/TermsAndConditions/${supplierId}`,
      termsAndConditions
    );
  }

  updateSupplier(supplierId: string, supplier: Supplier): Observable<Supplier> {
    return this.http.put<Supplier>(`${this.baseUrl}/${supplierId}`, supplier);
  }

  getAllSuppliers(): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(this.baseUrl + `/SuppliersList`);
  }

  getTruckingPricelistLookups(): Observable<TruckingPricelistLookups> {
    return this.http.get<TruckingPricelistLookups>(
      this.baseUrl + `/TruckingPricelistLookup`
    );
  }

  deleteSupplier(supplierId: string): Observable<Supplier> {
    return this.http.put<Supplier>(
      this.baseUrl + `/DeleteSupplier/${supplierId}`,
      null
    );
  }

  addTruckingPricelist(
    supplierId: string,
    truckingPricelist: TruckingPricelist
  ): Observable<Supplier> {
    return this.http.put<Supplier>(
      this.baseUrl + `/${supplierId}/TruckingPricelist`,
      truckingPricelist
    );
  }

  updateTruckingPricelist(
    supplierId: string,
    truckingPricelistId: string,
    truckingPricelist: TruckingPricelist
  ): Observable<Supplier> {
    return this.http.put<Supplier>(
      this.baseUrl + `/${supplierId}/TruckingPricelist/${truckingPricelistId}`,
      truckingPricelist
    );
  }

  deleteTruckingPricelist(
    supplierId: string,
    truckingPricelistId: string,
    isDomesticRoute: boolean
  ): Observable<TruckingPricelist> {
    return this.http.delete<TruckingPricelist>(
      this.baseUrl +
        `/${supplierId}/TruckingPriceList/${truckingPricelistId}?isDomesticTrucking=${isDomesticRoute}`
    );
  }

  getSupplierPortTruckingPriceLists(
    supplierId: string
  ): Observable<TruckingPricelist[]> {
    return this.http.get<TruckingPricelist[]>(
      this.baseUrl + `/${supplierId}/PortTruckingPriceList`
    );
  }

  getSupplierTruckingPriceList(
    supplierId: string,
    truckingPriceListId: string,
    isDomestic: boolean
  ): Observable<TruckingPricelist> {
    return this.http.get<TruckingPricelist>(
      this.baseUrl +
        `/${supplierId}/TruckingPriceList/${truckingPriceListId}/${isDomestic}`
    );
  }

  getSupplierDomesticTruckingPriceList(
    supplierId: string
  ): Observable<TruckingPricelist[]> {
    return this.http.get<TruckingPricelist[]>(
      this.baseUrl + `/${supplierId}/DomesticTruckingPriceList`
    );
  }

  deleteSupplierDocument(
    supplierId: string,
    documentSqlId: number,
    reason: string,
    parentDocumentSqlId?: number
  ): Observable<Supplier> {
    return this.http.put<Supplier>(
      this.baseUrl +
        `/${supplierId}/Documents/${documentSqlId}?reason=${reason}&parentDocumentSqlId=${parentDocumentSqlId}`,
      null
    );
  }

  getCustomsClearanceLookups(): Observable<CustomsCleranceLookUps> {
    return this.http.get<CustomsCleranceLookUps>(
      this.baseUrl + `/SupplierCustomsClearanceLookup`
    );
  }

  addCustomsClearancePricelist(
    supplierId: string,
    customsClearancePriceList: CustomsClearancePriceList
  ): Observable<Supplier> {
    return this.http.put<Supplier>(
      this.baseUrl + `/${supplierId}/CustomsClearancePriceList`,
      customsClearancePriceList
    );
  }

  getSupplierCustomsClearancePriceList(
    supplierId: string
  ): Observable<CustomsClearancePriceList[]> {
    return this.http.get<CustomsClearancePriceList[]>(
      this.baseUrl + `/${supplierId}/CustomsClearancePriceLists`
    );
  }

  deleteCustomsClearancePricelist(
    supplierId: string,
    customsClearancePriceListId: string
  ): Observable<CustomsClearancePriceList> {
    return this.http.delete<CustomsClearancePriceList>(
      this.baseUrl +
        `/${supplierId}/CustomsClearancePriceList/${customsClearancePriceListId}`
    );
  }

  getCustomsClearancePricelist(
    supplierId: string,
    customsClearancePriceListId: string
  ): Observable<CustomsClearancePriceList> {
    return this.http.get<CustomsClearancePriceList>(
      this.baseUrl +
        `/${supplierId}/CustomsClearancePriceList/${customsClearancePriceListId}`
    );
  }

  updateCustomsClearancePricelist(
    supplierId: string,
    customsClearancePriceListId: string,
    customsClearancePriceList: CustomsClearancePriceList
  ): Observable<Supplier> {
    return this.http.put<Supplier>(
      this.baseUrl +
        `/${supplierId}/CustomsClearancePriceList/${customsClearancePriceListId}`,
      customsClearancePriceList
    );
  } 
}
