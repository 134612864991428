import { Component, Input, OnInit } from '@angular/core';
import { CargoHandling } from 'src/app/shared/models/enums/cargo-handling.enum';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';
import { QuoteCargoDetails } from 'src/app/shared/quote/models/quote-cargo-details.model';
import { QuoteCargoPackaging } from 'src/app/shared/quote/models/quote-cargo-packaging.model';

import { CreateQuoteHelperService } from '../../../../create-quote/services/create-quote-helper.service';

@Component({
  selector: 'app-package-pallet-details',
  templateUrl: './package-pallet-details.component.html',
  styleUrls: ['./package-pallet-details.component.scss']
})
export class PackagePalletDetailsComponent implements OnInit {
  @Input() quote: QuoteBase;
  cargoHandling = CargoHandling;

  constructor(private createQuoteHelperService: CreateQuoteHelperService) {}

  ngOnInit(): void {}

  get cargoDetails(): QuoteCargoDetails {
    return this.quote.cargoDetails;
  }

  get hasPackages() {
    return this.cargoDetails?.hasPackage;
  }

  get hasPallets() {
    return this.cargoDetails?.hasPallet;
  }

  get isTotalsPackagesPallets() {
    return this.cargoDetails?.isPackageTotals;
  }

  get packagesTotals() {
    return this.cargoDetails?.packagesTotals;
  }

  get packages() {
    return this.cargoDetails?.packages;
  }

  get pallets() {
    return this.cargoDetails?.pallets;
  }

  get isSelectedDomesticService(): boolean {
    return this.createQuoteHelperService?.isSelectedDomesticService(
      this?.quote?.quoteServices
    );
  }

  get totalShipmentWeight(): string {
    let totalWeight = this.createQuoteHelperService.getTotalShipmentWeight(
      this.quote
    );
    return totalWeight.toString();
  }

  get chargeableWeight(): string {
    return this.createQuoteHelperService.getChargeableWeightForRateDetails(
      this.quote
    ).toFixed(2);
  }

  getPackageOrPalletVolume(cargoPackage: QuoteCargoPackaging): string {
    const totalVolume = this.createQuoteHelperService.getPackageOrPalletVolume(
      cargoPackage.length,
      cargoPackage.width,
      cargoPackage.height,
      cargoPackage.quantity
    );

    return totalVolume.toString();
  }

  get totalShipmentVolume(): string {
    let totalVolume = this.createQuoteHelperService.getTotalShipmentVolume(
      this.quote
    );
    return totalVolume.toString();
  }

  get sectionTitle() {
    if (this.isSelectedDomesticService) {
      return;
    }
    if (this.isAirAndOcean && (this.isLCLLoadType || this.isFCLLoadType)) {
      return 'Air Freight ';
    }
    if (this.hasBreakbulkPackages) {
      return 'Cargo';
    }
    return 'Packing Details';
  }

  get isAirAndOcean() {
    return this.createQuoteHelperService.isAirAndOcean(this.freightReference);
  }

  get freightReference() {
    return this.createQuoteHelperService.getFreightReference(this.quote);
  }

  get isLCLLoadType() {
    return this.createQuoteHelperService.isLCLLoadType(this.quote);
  }

  get isFCLLoadType() {
    return this.createQuoteHelperService.isFCLLoadType(this.quote);
  }

  get hasBreakbulkPackages() {
    return this.createQuoteHelperService.hasBreakbulkPackages(this.quote);
  }

  get breakbulkPackages() {
    if (this.hasBreakbulkPackages) {
      return this.cargoDetails?.breakbulkPackages;
    }
  }
}
