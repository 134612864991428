import { Component, Input, OnInit } from '@angular/core';
import { ProductRegistration } from '../../../create-product-registration/models/product-registration.model';
import { ProductDocument } from 'src/app/shared/models/product-document.model';
import { ProductRegistrationHelperService } from '../../../create-product-registration/service/product-registration-helper.service';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { DocumentTypeService } from 'src/app/admin-portal-configs/services/document-type.service';
import { DocumentTypeEnum } from 'src/app/shared/models/enums/document-type.enum';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { UploadedFile } from 'src/app/approvals/models/uploaded-file.model';

@Component({
  selector: 'app-display-document-fields',
  templateUrl: './display-document-fields.component.html',
  styleUrls: ['./display-document-fields.component.scss']
})
export class DisplayDocumentFieldsComponent implements OnInit {
  @Input() productRegistration: ProductRegistration;
  @Input() approvalId: string;
  @Input() form: UntypedFormGroup;
  @Input() documentControlName: string;
  @Input() fieldName: string;
  @Input() popupTitle: string;
  @Input() fieldPlaceholder: string;
  @Input() documentType: string;
  @Input() isRequired: boolean;
  @Input() isReplaceButtonhidden: boolean;

  documentTypes: DocumentType[] = [];
  registrationLicenseDocumentTypes: DocumentType[] = [];

  constructor(
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private documentTypeService: DocumentTypeService
  ) {}

  ngOnInit() {
    if (this.isProductRegistration) {
      this.getProductRegistrationDocuments();
    } else {
      this.getDocumentTypeByName(this.documentType);
    }
  }

  getDocumentTypeByName(documentTypeName: string): void {
    this.documentTypeService
      .getDocumentTypeByName(documentTypeName)
      .subscribe(resp => {
        if (documentTypeName === DocumentTypeEnum.RegistrationLicense) {
          this.registrationLicenseDocumentTypes.push(resp);
          return;
        }
        this.documentTypes.push(resp);
      });
  }

  getProductRegistrationDocuments(): void {
    this.getDocumentTypeByName(DocumentTypeEnum.TechnicalFile);
    this.getDocumentTypeByName(DocumentTypeEnum.RegistrationLicense);
  }

  get registrationLicense(): ProductDocument {
    return this.productRegistrationHelperService.getProductRegistrationLicenseDocument(
      this.productRegistration
    );
  }

  get productRegistrationId(): string {
    return this.productRegistrationHelperService.getProductId(
      this.productRegistration
    );
  }

  get documentParentType(): string {
    return this.isProductRegistration
      ? DocumentParentType.ProductRegistration
      : DocumentParentType.Approval;
  }

  get documentParentTypeId(): string {
    return this.isProductRegistration
      ? this.productRegistrationId
      : this.approvalId;
  }

  get isProductRegistration(): boolean {
    return this.productRegistration != null;
  }

  get technicalFile(): ProductDocument {
    return this.productRegistrationHelperService.getProductRegistrationTechnicalFileDocument(
      this.productRegistration
    );
  }

  get approvalDocument(): UploadedFile {
    return this.form?.get(this.documentControlName)?.value;
  }

  get document() {
    return this.isProductRegistration
      ? this.technicalFile
      : this.approvalDocument;
  }
}
