import { Component, Input, OnInit } from '@angular/core';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import { UploadDocumentComponent } from 'src/app/shared/components/upload-document/upload-document/upload-document.component';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { DocumentTypeEnum } from 'src/app/shared/models/enums/document-type.enum';
import { UploadDocumentPopUpPurpose } from 'src/app/shared/models/enums/upload-document-pop-up-purpose.enum';
import { ProductDocument } from 'src/app/shared/models/product-document.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-display-document',
  templateUrl: './display-document.component.html',
  styleUrls: ['./display-document.component.scss']
})
export class DisplayDocumentComponent implements OnInit {
  @Input() document;
  @Input() documentParentTypeId: string;
  @Input() documentTypes: DocumentType[] = [];
  @Input() form: UntypedFormGroup;
  @Input() documentControlName: string;
  @Input() parentType: string;
  @Input() isReplaceButtonhidden: boolean;

  isLoading: boolean;

  constructor(
    private fileViewerService: FileViewerService,
    private alertService: AlertService,
    private documentService: DocumentService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.documentService.fileUploadingEmitter.subscribe(res => {
      if (res?.uploadedFile?.typeName === this.document?.documentTypeName) {
        this.isLoading = true;
        this.addUploadingDocument(res?.data);
      }
    });
  }

  viewFile(): void {
    if (this.document) {
      this.fileViewerService.view(this.path);
    }
  }

  download(): void {
    var link = document.createElement('a');
    link.setAttribute('download', this.fileName);
    link.setAttribute('href', this.path);

    document.body.appendChild(link);
    link.click();
    link.remove();
    this.alertService.success('Document successfully downloading.');
  }

  replace(): void {
    const parentType = this.parentType
      ? this.parentType
      : DocumentParentType.ProductRegistration;
    this.dialogService.open(
      UploadDocumentComponent,
      {
        documentTypes: this.documentTypes,
        parentTypeId: this.documentParentTypeId,
        parentType: parentType,
        formTitle: 'Replace Document',
        popUpPurpose: UploadDocumentPopUpPurpose.ReplaceExistingDocument,
        documentToReplaceSqlId: this.documentSqlId,
        currentDocument: this.document,
        isUploadLicense: this.isRegistrationLicense,
        isReplaceLicense: this.isRegistrationLicense
      },
      '600px'
    );
  }

  addUploadingDocument(uploadedDocument): void {
    if (uploadedDocument != null) {
      this.isLoading = false;
      this.document = uploadedDocument;

      this.form?.get(this.documentControlName)?.setValue({
        documentSqlId: uploadedDocument?.documentSqlId,
        path: uploadedDocument?.path,
        fileName: uploadedDocument?.fileName,
        description: uploadedDocument?.description,
        uploadedBy: uploadedDocument?.uploadedBy,
        uploadDateTime: uploadedDocument?.uploadDateTime,
        createdAt: uploadedDocument?.uploadDateTime,
        documentTypeName: uploadedDocument?.documentTypeName,
        documentTypeId: uploadedDocument?.documentTypeId,
        validToDate: uploadedDocument?.validToDate,
        oldVersions: uploadedDocument?.oldVersions
      });
    }
  }

  get truncatedFileName(): string {
    return this.fileName?.length > 20
      ? this.fileName?.slice(0, 35) + '...'
      : this.fileName;
  }

  get fileName(): string {
    return this.document?.fileName;
  }

  get createdAt(): Date {
    return this.document?.createdAt ?? this.document?.uploadDateTime;
  }

  get uploadedBy(): string {
    return this.document?.uploadedBy?.fullName;
  }

  get companyName(): string {
    return this.document?.uploadedBy?.companyName;
  }

  get path(): string {
    return this.document?.path;
  }

  get oldVersions(): ProductDocument[] {
    return this.document?.oldVersions;
  }

  get documentSqlId(): number {
    return this.document?.documentSqlId;
  }

  get isRegistrationLicense(): boolean {
    return (
      this.document?.documentTypeName === DocumentTypeEnum.RegistrationLicense
    );
  }

  get isInvoiceDocument(): boolean {
    return (
      this.document?.documentTypeName === DocumentTypeEnum.CommercialInvoice
    );
  }
}
