<div *ngIf="!isLoading">
  <div class="top-bar-container">
    <app-top-bar
      [moduleName]="productRegistrationModuleName"
      [productRegistrationDetails]="productRegistrationDetails"
      [nextActivities]="nextActivities"
      (deleteClicked)="cancelRequest()"
      (pauseResumeClicked)="pauseResumeRequest()"
    ></app-top-bar>
  </div>
  <div class="product-registration-details-container w-100">
    <app-product-registration-comment-section
      class="product-comments-section position-fixed h-100"
      [productRegistrationId]="productId"
      [commentWithActionActors]="productRegistrationCommentWithActionActors"
      [newCommentAdded]="newProductRegistrationCommentWithActionActor"
    >
    </app-product-registration-comment-section>

    <app-warning-badge
      *ngIf="isCustomer && hasPendingActions"
      class="warning-badge"
      [message]="
        'Account Manager Has Set Pending Comment, Please Fulfil the Requirements.'
      "
    ></app-warning-badge>
    <div class="tabs">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Overview" *ngIf="canViewOverviewTab">
          <ng-template matTabContent>
            <div class="title d-flex justify-content-between">
              <div>
                <svg-icon name="chargesSummary"></svg-icon> REGISTRATION DETAILS
              </div>

              <button
                [routerLink]="[
                  '../../create-product-registration/',
                  productId,
                  'edit-product',
                  'product-registration-details'
                ]"
                class="edit-section-button text-left"
                [class]="isEditDetailsDisabled ? 'disabled' : ''"
                [disabled]="isEditDetailsDisabled"
                *ngIf="canEditProductRegistration"
              >
                <svg-icon name="editQuote"></svg-icon>
                Edit
              </button>
            </div>

            <app-product-data-summary
              [productRegistration]="
                productRegistrationDetails?.productRegistration
              "
              [productsDetails]="productRegistrationDetails?.productDetails"
            ></app-product-data-summary>
          </ng-template>
        </mat-tab>
        <mat-tab label="Activity Log" *ngIf="canViewActivityLogTab">
          <ng-template matTabContent>
            <app-progress-point
              *ngFor="
                let progressAndActivityLogs of productRegistrationDetails.progressAndActivityLogs;
                let i = index
              "
              [isDisabled]="i > currentProgressPointIndex"
              [isCurrentProgress]="
                progressAndActivityLogs?.progress?.name ==
                currentProgressPointName
              "
              [progressAndActivityLogs]="progressAndActivityLogs"
              [isDisabletrackingLine]="
                i ==
                productRegistrationDetails?.progressAndActivityLogs?.length - 1
              "
              [productRegistrationComments]="
                productRegistrationCommentWithActionActors
              "
              [productId]="productId"
            ></app-progress-point>
          </ng-template>
        </mat-tab>
        <mat-tab label="Charges">
          <ng-template matTabContent>
            <app-charges-tab
              [entityName]="productRegistrationEntity"
              [charges]="productRegistration?.charges"
            >
            </app-charges-tab>
          </ng-template>
        </mat-tab>
        <mat-tab label="Products" *ngIf="canViewProductsTab">
          <ng-template matTabContent>
            <div class="products-list col-12 px-0 w-100 d-flex">
              <div class="column-title col-2">Part Number</div>
              <div class="column-title col-3 product-description-cell">
                Product Description
              </div>
              <div class="column-title col-2">Documents</div>
              <div class="column-title col-2 notes-cell">Notes</div>
              <div class="column-title col-2 actions-cell">Actions</div>
              <div class="variants-arrow col-1"></div>
            </div>

            <app-selected-product-in-tab
              *ngFor="let product of productRegistrationDetails?.productDetails"
              [moduleName]="productRegistrationModuleName"
              [parentId]="productRegistrationDetails?.productRegistration?.id"
              [isDisabled]="false"
              [product]="product"
            ></app-selected-product-in-tab>
          </ng-template>
        </mat-tab>
        <mat-tab label="Lead Times" *ngIf="canViewLeadTimesTab">
          <ng-template matTabContent>
            <app-lead-times
              [productRegistrationLeadTimes]="
                productRegistrationDetails?.productLeadTimeLogs
              "
            ></app-lead-times>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
