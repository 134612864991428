<div class="non-admin-sidebar-container text-center h-100">
  <div class="nav-item menu-icon">
    <svg-icon name="menu-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    ngbDropdown
    *ngIf="
      canCreateQuotes ||
      canCreateShipment ||
      isMedicalOrPharmaceuticalIndustry ||
      canCreateProductRegistration
    "
  >
    <div ngbDropdownToggle>
      <svg-icon name="plus-sidebar"></svg-icon>
    </div>
    <div ngbDropdownMenu class="creation-popup">
      <div
        class="dropdown-item"
        *ngIf="canCreateQuotes"
        (click)="
          navigateToPage(
            '/company/' + companyId + '/quotes/createquote/chooseservices'
          )
        "
      >
        Quote
      </div>
      <div
        class="dropdown-item"
        *ngIf="canCreateShipment"
        (click)="
          navigateToPage('/company/' + companyId + '/shipments/createshipment')
        "
      >
        Shipment
      </div>
      <div
        class="dropdown-item"
        (click)="
          navigateToPage(
            '/company/' + companyId + '/products-library/create-product/details'
          )
        "
        *ngIf="canCreateProduct"
      >
        Product
      </div>
      <div
        class="dropdown-item"
        *ngIf="
          isMedicalOrPharmaceuticalIndustry && canCreateProductRegistration
        "
        (click)="
          navigateToPage(
            '/company/' +
              companyId +
              '/registrations/create-product-registration/product-registration-details'
          )
        "
      >
        Registration
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        (click)="
          navigateToPage('/company/' + companyId + '/approvals/create-approval')
        "
      >
        Approval
      </div>
      <div
        class="dropdown-item"
        *ngIf="canAccessWarehousingList"
        (click)="
          navigateToPage(
            '/company/' + companyId + '/warehousing/add-edit-warehousing'
          )
        "
      >
        Warehousing
      </div>
    </div>
  </div>
  <div
    class="nav-item cursor-pointer"
    (click)="
      navigateToPage(
        '/company/' + companyId + '/' + nonAdminSidebarEnum.Dashboard
      )
    "
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Dashboard) }"
    [ngbPopover]="'Dashboard'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.Dashboard) ? 'selected-popover' : ''
    "
  >
    <svg-icon name="dashboard-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewShipments || canViewShipmentsForSelectedCustomer"
    (click)="navigateToPage(currentShipmentRoute)"
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Shipments) &&
        !isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
    }"
    [ngbPopover]="'Shipments'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.Shipments) &&
      !isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
        ? 'selected-popover'
        : ''
    "
  >
    <svg-icon name="shipments-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewQuotes"
    (click)="
      onChangeQuotesListView(
        '/company/' + companyId + '/' + nonAdminSidebarEnum.Quotes
      )
    "
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Quotes) &&
        !isSelectedTab(nonAdminSidebarEnum.OperationalOversight) &&
        !isSelectedTab('quotes-v2')
    }"
    [ngbPopover]="'Quotes'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.Quotes) &&
      !isSelectedTab(nonAdminSidebarEnum.OperationalOversight) &&
      !isSelectedTab('quotes-v2')
        ? 'selected-popover'
        : ''
    "
  >
    <svg-icon name="quotes-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngClass]="{
      selected:
        isSelectedTab(nonAdminSidebarEnum.Products) ||
        isSelectedTab(nonAdminSidebarEnum.Registrations) ||
        isSelectedTab(nonAdminSidebarEnum.Approvals)
    }"
    *ngIf="isMedicalOrPharmaceuticalIndustry || canViewProductsList"
    [ngbPopover]="'Products'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.Products) ||
      isSelectedTab(nonAdminSidebarEnum.Registrations) ||
      isSelectedTab(nonAdminSidebarEnum.Approvals)
        ? 'selected-popover'
        : ''
    "
  >
    <button
      class="btn btn-link dropdown-button"
      id="productsDropdownMenu"
      ngbDropdownToggle
    >
      <svg-icon name="products-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="products-popup">
      <div
        class="dropdown-item"
        (click)="navigateToPage('/company/' + companyId + '/products-library')"
        *ngIf="canViewProductsList"
      >
        Product Library
      </div>
      <div
        class="dropdown-item"
        *ngIf="
          isMedicalOrPharmaceuticalIndustry && canViewRegistrationListAccess
        "
        (click)="navigateToPage('/company/' + companyId + '/registrations')"
      >
        Registrations
      </div>
      <div
        class="dropdown-item"
        *ngIf="isMedicalOrPharmaceuticalIndustry"
        (click)="navigateToPage('/company/' + companyId + '/approvals')"
      >
        Approvals
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngClass]="{
      selected: isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
    }"
    *ngIf="
      canManageShipmentsOperationalOversight ||
      canManageAllQuotesOperationalOversight
    "
    [ngbPopover]="'Operational Oversight'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.OperationalOversight)
        ? 'selected-popover'
        : ''
    "
  >
    <button
      class="btn btn-link dropdown-button"
      id="productsDropdownMenu"
      ngbDropdownToggle
    >
      <svg-icon name="operational-oversight-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="products-popup">
      <div
        *ngIf="canManageShipmentsOperationalOversight"
        class="dropdown-item"
        (click)="
          onChangeShipmentsListView(
            '/shipments',
            nonAdminSidebarEnum.OperationalOversight
          )
        "
      >
        Shipments
      </div>
      <div
        *ngIf="canManageAllQuotesOperationalOversight"
        class="dropdown-item"
        (click)="
          onChangeQuotesListView(
            '/quotes',
            nonAdminSidebarEnum.OperationalOversight
          )
        "
      >
        Quotes
      </div>
    </div>
  </div>

  <div
    class="nav-item cursor-pointer"
    *ngIf="canManageForm4Requests"
    (click)="navigateToPage('/form4requests')"
    [queryParams]="{ view: nonAdminSidebarEnum.Form4Requests }"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Form4Requests) }"
    [ngbPopover]="'Form 4 Requests'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.Form4Requests) ? 'selected-popover' : ''
    "
  >
    <svg-icon name="form4-sidebar"></svg-icon>
  </div>
  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewReports"
    (click)="navigateToPage('/reports')"
    [ngClass]="{ selected: isSelectedTab(nonAdminSidebarEnum.Reports) }"
    [ngbPopover]="'Reports'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="
      isSelectedTab(nonAdminSidebarEnum.Reports) ? 'selected-popover' : ''
    "
  >
    <svg-icon name="reports-sidebar"></svg-icon>
  </div>
  <!-- New Quotes, For testing purpose-->
  <div
    class="nav-item cursor-pointer"
    *ngIf="canViewQuotes && isDev"
    (click)="navigateToPage('/company/' + companyId + '/quotes-v2')"
    [ngClass]="{ selected: isSelectedTab('quotes-v2') }"
    [ngbPopover]="'Quotes'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [popoverClass]="isSelectedTab('quotes-v2') ? 'selected-popover' : ''"
  >
    <svg-icon name="quotes-sidebar-v2"></svg-icon>
  </div>
  <div
    *ngIf="canAccessWarehousingList"
    class="nav-item cursor-pointer"
    (click)="navigateToPage('/company/' + companyId + '/warehousing')"
    [ngbPopover]="'Warehousing'"
    triggers="mouseenter:mouseleave"
    placement="right"
  >
    <svg-icon name="warehousing-sidebar"></svg-icon>
  </div>
</div>
