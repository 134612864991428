import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransactionType } from 'src/app/shared/models/enums/transaction-type.enum';
import { TransportTypeEnum } from 'src/app/shared/models/enums/transport-type.enum';
import { Observable, Subject, concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { PortService } from 'src/app/admin-portal-configs/services/port.service';
import { CompanyService } from 'src/app/company/services/company.service';
import { PortDto } from 'src/app/admin-portal-configs/models/portDto.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { LoadType } from 'src/app/shared/models/load-type.model';
import { BaseNamedEntity } from 'src/app/shared/models/shipment/base-named-entity.model';
import { Router } from '@angular/router';
import { TruckingLoadType } from '../../../shared/models/enums/trucking-load-type.enum';
import { SupplierHelperService } from 'src/app/supplier/services/supplier-helper.service';

@Component({
  selector: 'app-pricelist-clearance-details',
  templateUrl: './pricelist-clearance-details.component.html',
  styleUrls: ['./pricelist-clearance-details.component.scss']
})
export class PricelistClearanceDetailsComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() loadTypes: LoadType[];
  @Input() containerTypes: BaseNamedEntity[];
  @Input() isAllPortsSelectedRequired: boolean = true;
  @Input() hasNameField: boolean = false;
  originalLoadTypes: LoadType[];

  transactionTypes: string[];
  freightTypes: string[];

  portsObservable: Observable<any>;
  ports$ = new Subject<string>();
  isPortsLoading = false;
  lastPorts: PortDto[] = [];
  egyptId: number;
  isAllPortSelected: boolean;

  constructor(
    private portService: PortService,
    private companyService: CompanyService,
    private formsHelperService: FormsHelperService,
    private supplierHelperService: SupplierHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.isAllPortsSelectedRequired)
      this.isAllPortSelected = this.form?.get('isAllPortsSelected')?.value;
    this.selectOrDeselectAllPorts();
    this.onChangeLoadType();
    this.getStaticLookups();
    this.getEgyptId();
  }

  ngOnChanges(): void {
    if (this.loadTypes?.length > 0) {
      this.originalLoadTypes = [...this.loadTypes];
    }
  }

  getStaticLookups(): void {
    this.transactionTypes = [TransactionType.Import, TransactionType.Export];
    this.freightTypes = [
      TransportTypeEnum.Air,
      TransportTypeEnum.Ocean,
      TransportTypeEnum.Land
    ];
  }

  getEgyptId(): void {
    this.companyService.getEgyptId().subscribe(data => {
      this.egyptId = data;
      this.loadPorts$();
    });
  }

  loadPorts$(): void {
    this.portsObservable = concat(
      of([]),
      this.ports$.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(_ => (this.isPortsLoading = true)),
        switchMap(searchText => {
          return this.portService
            .searchPorts(searchText, [this.selectedFreightType], true)
            .pipe(
              catchError(() => of([])),
              tap((value: PortDto[]) => {
                this.isPortsLoading = false;
                this.lastPorts = value;
              })
            );
        })
      )
    );
  }

  deletePortsSelectedValues(): void {
    this.form?.get('portsOfClearance')?.setValue(null);
    this.loadPorts$();
  }

  deleteSelectedLoadTypeValues(): void {
    this.form?.get('loadTypes')?.setValue(null);
    this.loadTypes = [...this.originalLoadTypes];
  }

  changeFreightType(): void {
    this.deletePortsSelectedValues();
    this.deleteSelectedLoadTypeValues();

    if (!this.isAirFreight) {
      this.addValidators('loadTypes');
    }

    if (this.isLandFreight) {
      this.loadTypes = this.loadTypes.filter(
        x => x.name == TruckingLoadType.FTL || x.name == TruckingLoadType.LTL
      );
    } else if (!this.isAirFreight) {
      this.loadTypes = this.loadTypes.filter(
        x => x.name != TruckingLoadType.FTL && x.name != TruckingLoadType.LTL
      );
    } else {
      this.removeValidators('loadTypes');
      this.form?.get('loadTypes')?.setValue(null);
      this.removeValidators('containerTypes');
      this.form?.get('containerTypes')?.setValue(null);
    }
  }

  selectOrDeselectAllPorts(change?: MatCheckboxChange): void {
    if (this.isAllPortsSelectedRequired)
      this.isAllPortSelected = this.form?.get('isAllPortsSelected')?.value;
    if (this.isAllPortSelected) {
      this.removeValidators('portsOfClearance');
      this.deletePortsSelectedValues();
    } else {
      this.addValidators('portsOfClearance');
    }
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.form,
      formControlName
    );
  }

  removeValidators(controlName: string) {
    this.formsHelperService.clearValidator(this.form, controlName);
  }

  addValidators(controlName: string) {
    this.formsHelperService.addRequiredValidator(this.form, controlName);
  }

  onChangeLoadType(controlName?: string): void {
    if (controlName != null) {
      this.updateFormController(controlName);
    }
    if (this.isLCLLoadType || this.isFCLLoadType) {
      this.addValidators('containerTypes');
    } else {
      this.removeValidators('containerTypes');
      this.form?.get('containerTypes')?.setValue(null);
    }
  }

  updateFormController(controlName: string): void {
    let toBeModifed = this.form?.get(controlName)?.value;
    let modifiedObject: { id: number; name: string } = {
      id: toBeModifed.id,
      name: toBeModifed.name
    };
    this.form?.get(controlName)?.setValue(modifiedObject);
  }

  get selectedFreightType(): string {
    return this.form?.get('freightType')?.value;
  }

  get selectedLoadType(): string {
    return this.form?.get('loadTypes')?.value?.name;
  }

  get isDisabledPortOfClearance(): boolean {
    if (this.isLandFreight) {
      return (
        this.form.get('transactionTypes').value == null ||
        this.form.get('transactionTypes').value.length < 1
      );
    }

    return this.selectedFreightType == null || this.isAllPortSelected;
  }

  get isAirFreight(): boolean {
    return this.supplierHelperService.isAirFreight(this.selectedFreightType);
  }

  get isLandFreight(): boolean {
    return this.supplierHelperService.isLandFreight(this.selectedFreightType);
  }

  get isLCLLoadType(): boolean {
    return this.supplierHelperService.isLCLLoadType(this.selectedLoadType);
  }

  get isFCLLoadType(): boolean {
    return this.supplierHelperService.isFCLLoadType(this.selectedLoadType);
  }

  selectedContainerTypes(containerTypes: BaseNamedEntity[]): string {
    return containerTypes.map(containerType => containerType?.name).join(', ');
  }

  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }

  get isShowAllPortsCheckbox(): boolean {
    if (this.isEditRoute && !this.isAllPortSelected) {
      return false;
    }
    return !this.isLandFreight && this.isAllPortsSelectedRequired;
  }
}
