import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QuoteColumn } from '../models/quote-column.model';

@Injectable({
  providedIn: 'root'
})
export class QuoteViewColumnService {
  baseURL = environment.apiUrl + 'QuoteViewColumn';

  constructor(private http: HttpClient) {}

  getQuoteColumns(): Observable<QuoteColumn[]> {
    return this.http.get<QuoteColumn[]>(this.baseURL);
  }

  getEmployeeSelectedOrDefaultColumns(): Observable<QuoteColumn[]> {
    return this.http.get<QuoteColumn[]>(this.baseURL + `/SelectedColumn`);
  }

  updateEmployeeSelectedColumns(
    selectedColumns: string[]
  ): Observable<QuoteColumn[]> {
    return this.http.put<QuoteColumn[]>(this.baseURL, selectedColumns);
  }

  updateEmployeeColumnOrders(
    quoteColumns: QuoteColumn[]
  ): Observable<QuoteColumn[]> {
    return this.http.put<QuoteColumn[]>(
      this.baseURL + `/order`,
      quoteColumns
    );
  }
}
