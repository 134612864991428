import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastaModule } from 'ngx-toasta';

import { AppErrorHandler } from './app-error.handler';
import { AppRoutingModule } from './app-routing.module';
import { AccountEndpoint } from './shared/services/account-endpoint.service';
import { AlertService } from './shared/services/alert.service';
import { AppTitleService } from './shared/services/app-title.service';
import {
    AppTranslationService,
    TranslateLanguageLoader
} from './shared/services/app-translation.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { LocalStoreManager } from './shared/services/local-store-manager.service';
import { NotificationService } from './shared/services/notification.service';
import { OidcHelperService } from './shared/services/oidc-helper.service';
import { ThemeManager } from './shared/services/theme-manager';

import { BootstrapTabDirective } from './shared/directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './shared/directives/bootstrap-toggle.directive';

import { AppComponent } from './app.component';
import { SettingsComponent } from './shared/components/settings/settings.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeagoModule } from 'ngx-timeago';
import { HelperService } from './shared/services/helper.service';
import { NotificationSettingService } from './shared/services/notification-setting.service';

import { AgmCoreModule } from '@agm/core';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
    MAT_DIALOG_DATA,
    MAT_DIALOG_SCROLL_STRATEGY
} from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgIdleModule } from '@ng-idle/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import {
    svgIconArrowDown,
    svgIconarrowToLeft,
    svgIconBlueArrowDown,
    svgIconMessage,
    svgIconNotification,
    svgIconRedInfo
} from '../../projects/svg-icons/src/lib/svg-icons';
import { SvgIconsRegistery } from '../../projects/svg-icons/src/lib/svg-icons.registery';
import { ConfigsListComponent } from './admin-portal-configs/pages/configs-list/configs-list.component';
import { CompanyService } from './company/services/company.service';
import { PreventUnsavedChanges } from './createShipment/guards/prevent-unsaved-changes.guard';
import { ShipmentServicesResolver } from './createShipment/resolvers/shipment-services.resolver';
import { EmployeeProfileModule } from './employee-profile/employee-profile.module';
import { ProductRegistrationModule } from './product-registration/product-registration.module';
import { RoleEditorComponent } from './shared/components/controls/role-editor.component';
import { RolesManagementComponent } from './shared/components/controls/roles-management.component';
import { UserInfoComponent } from './shared/components/controls/user-info.component';
import { UsersManagementComponent } from './shared/components/controls/users-management.component';
import { NavBarComponent } from './shared/components/nav-bar/nav-bar.component';
import { AuthInterceptorService } from './shared/interceptors/auth.interceptor';
import { GroupByPipe } from './shared/pipes/group-by.pipe';
import { AccountManagersListResolver } from './shared/resolvers/account-managers-list.resolver';
import { CommissionTieredRateResolver } from './shared/resolvers/commission-tiered-rate.resolver';
import { AccountService } from './shared/services/account.service';
import { DocumentService } from './shared/services/document.service';
import { PaginatorIntl } from './shared/services/paginator-intl';
import { SharedModule } from './shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    OAuthModule.forRoot(),
    ToastaModule.forRoot(),
    NgSelectModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    TimeagoModule.forRoot(),
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    NgxMaskModule.forRoot(),
    EmployeeProfileModule,
    SharedModule,
    NgIdleModule.forRoot(),
    ProductRegistrationModule
  ],
  declarations: [
    AppComponent,
    SettingsComponent,
    UsersManagementComponent,
    GroupByPipe,
    UserInfoComponent,
    RolesManagementComponent,
    ConfigsListComponent,
    RoleEditorComponent,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    NavBarComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: MatPaginatorIntl, useClass: PaginatorIntl },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: (scrollStrategyOptions: ScrollStrategyOptions) =>
        scrollStrategyOptions.noop,
      deps: [ScrollStrategyOptions]
    },

    AlertService,
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    OidcHelperService,
    CompanyService,
    HelperService,
    AccountManagersListResolver,
    DocumentService,
    NotificationSettingService,
    PreventUnsavedChanges,
    ShipmentServicesResolver,
    CommissionTieredRateResolver,
    SharedModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([
      svgIconArrowDown,
      svgIconNotification,
      svgIconMessage,
      svgIconarrowToLeft,
      svgIconRedInfo,
      svgIconBlueArrowDown
    ]);
  }
}
