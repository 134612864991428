import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from 'src/app/company/models/company.model';
import { environment } from 'src/environments/environment';
import { ApprovalsServiceForCustomer } from '../models/approvals-service-for-customer.model';
import { CompanySelectedService } from '../models/company-selected-service.model';
import { ProductRegistrationServiceCustomer } from '../models/Product-Registration-Service-Customer';
import { CustomerTruckingRoutesLookups } from '../models/customer-trucking-routes-search.model';
import { IorOrEorCustomerServiceLookups } from '../models/ior-or-eor-customer-service-lookups.model';
import { CustomerIorOrEorService } from '../models/customer-ior-or-eor-service.model';
import { AccountManagementCustomerServiceLookups } from '../models/account-management-customer-service-lookups.model';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { CustomerAccountManagementService } from '../models/customer-account-management-service.model';
import { CustomsClearanceRouteDetails } from '../models/customs-clearance-route-details.model';
import { CustomsClearanceRouteSearchViewModel } from '../models/customs-clearance-route-search-view-model.model';
import {CustomsClearanceRate} from "../../company/models/customs-clearance-rate.model";
import { CustomerTruckingRouteViewModel } from '../models/customer-trucking-route-view-model';
import { CustomerTruckingRoute } from '../models/customer-trucking-route.model';

@Injectable({ providedIn: 'root' })
export class CustomerServicesService {
  company: Company;
  baseURL = environment.apiUrl + 'customerSelectedServices';
  selectedServiceChanged = new EventEmitter();

  constructor(private http: HttpClient) {}

  getApprovalsServicesForCustomer(
    customerId: number
  ): Observable<ApprovalsServiceForCustomer[]> {
    return this.http.get<ApprovalsServiceForCustomer[]>(
      `${this.baseURL}/ApprovalsServiceForCustomer/${customerId}`
    );
  }

  updateApprovalsServiceForCustomer(
    customerId: number,
    approvalsServiceForCustomer: ApprovalsServiceForCustomer[]
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/ApprovalsServiceForCustomer/${customerId}`,
      approvalsServiceForCustomer
    );
  }

  getSelectedServicesForCustomerWithServiceProvider(
    customerId: number
  ): Observable<CompanySelectedService[]> {
    return this.http.get<CompanySelectedService[]>(
      `${this.baseURL}/${customerId}`
    );
  }

  updateCustomerSelectedServices(
    companySelectedService: CompanySelectedService
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/SelectedService`,
      companySelectedService
    );
  }

  getSelectedServicesForCustomer(
    customerId: number
  ): Observable<CompanySelectedService[]> {
    return this.http.get<CompanySelectedService[]>(
      `${this.baseURL}/${customerId}/SelectedService`
    );
  }

  getProductRegistrationsServiceForCustomer(
    customerId: number
  ): Observable<ProductRegistrationServiceCustomer[]> {
    return this.http.get<ProductRegistrationServiceCustomer[]>(
      `${this.baseURL}/ProductRegistrationsServiceCustomer/${customerId}`
    );
  }

  getCustomerTruckingRoutesLookUps(): Observable<
    CustomerTruckingRoutesLookups
  > {
    return this.http.get<CustomerTruckingRoutesLookups>(
      `${this.baseURL}/TruckingRoutesLookups`
    );
  }

  getIorOrEorLookups(
    serviceType: string
  ): Observable<IorOrEorCustomerServiceLookups> {
    return this.http.get<IorOrEorCustomerServiceLookups>(
      `${this.baseURL}/IorOrEorLookups/${serviceType}`
    );
  }

  getAccountManagementLookups(): Observable<
    AccountManagementCustomerServiceLookups
  > {
    return this.http.get<AccountManagementCustomerServiceLookups>(
      `${this.baseURL}/AccountManagementLookups`
    );
  }

  addCustomerAccountManagementService(
    accountManagementService: CustomerAccountManagementService
  ): Observable<CustomerAccountManagementService> {
    return this.http.post<CustomerAccountManagementService>(
      `${this.baseURL}/AccountManagement`,
      accountManagementService
    );
  }

  updateCustomerAccountManagementService(
    accountManagementService: CustomerAccountManagementService
  ): Observable<CustomerAccountManagementService> {
    return this.http.put<CustomerAccountManagementService>(
      `${this.baseURL}/AccountManagement`,
      accountManagementService
    );
  }

  getCustomerAccountManagementService(
    companyId: number
  ): Observable<CustomerAccountManagementService> {
    return this.http.get<CustomerAccountManagementService>(
      `${this.baseURL}/${companyId}/AccountManagement`
    );
  }
 
  getAllCustomerCustomsClearanceRates(
    customerId: number,
    pageIndex: number = 0,
    pageSize: number = 10
  ): Observable<Paginated> {
    return this.http.get<Paginated>(
      `${this.baseURL}/CustomerCustomsClearanceRate?companyId=${customerId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getCustomerCustomsClearanceRateById(
    rateId: number
  ): Observable<CustomsClearanceRate> {
    return this.http.get<CustomsClearanceRate>(
      `${this.baseURL}/CustomerCustomsClearanceRate/${rateId}`
    );
  }

  saveIorOrEorServiceForCustomer(
    serviceType: string,
    customerIorOrEorService: CustomerIorOrEorService
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}/IorOrEorServiceForCustomer/${serviceType}`,
      customerIorOrEorService
    );
  }

  updateProductRegistrationCustomer(
    customerId: number,
    productRegistrationServiceCustomer: ProductRegistrationServiceCustomer[]
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/ProductRegistrationsServiceCustomer/${customerId}`,
      productRegistrationServiceCustomer
    );
  }
 
  getCustomerIorOrEorService(
    companyId: number,
    freePLServiceId: number
  ): Observable<CustomerIorOrEorService> {
    return this.http.get<CustomerIorOrEorService>(
      `${this.baseURL}/${companyId}/IorOrEorServiceForCustomer/${freePLServiceId}`
    );
  }

  updateCustomerIorOrEorService(
    customerIorEorService: CustomerIorOrEorService
  ): Observable<any> {
    return this.http.put(
      `${this.baseURL}/IorOrEorServiceForCustomer`,
      customerIorEorService
    );
  } 

  searchCustomsClearancePriceLists(
    routeSearch: CustomsClearanceRouteSearchViewModel
  ): Observable<CustomsClearanceRouteDetails[]> {
    return this.http.post<CustomsClearanceRouteDetails[]>(
      `${this.baseURL}/SearchCustomsClearancePriceLists`,
      routeSearch
    );
  }

  addCustomsClearancePriceListRate(
    rate: CustomsClearanceRouteDetails
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}/CustomerCustomsClearanceRate`,
      rate
    );
  }

  
  addCustomerTruckingRoute(
    route:CustomerTruckingRouteViewModel
  ): Observable<CustomerTruckingRoute> {
    return this.http.post<CustomerTruckingRoute>(
      `${this.baseURL}/CustomerTruckingRoute`,
      route
    );
  }

  getPaginatedCustomerCustomsClearanceRates(
    companyId: number,
    pageIndex: number = 0,
    pageSize: number = 0
  ): Observable<Paginated> {
    const url = `${this.baseURL}/CustomerCustomsClearanceRate/?companyId=${companyId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.http.get<Paginated>(url);
  }
}
