export enum ServiceTypes {
  IOR = 'IOR',
  EOR = 'EOR',
  Domestic = 'Domestic',
  CustomsClearance = 'Customs Clearance',
  AccountManagement = 'Account Management',
  Freight = 'Freight',
  Trucking = 'Trucking',
  ImportExport = 'IOR/EOR',
  PortToDoor = 'Port to Door',
  DoorToPort = 'Door to Port',
  PortToPort = 'Port to Port',
  DoorToDoor = 'Door to Door',
  DomesticTrucking = 'Domestic Trucking',
  AdditionalCharges = 'Additional Charges',
  ProductRegistration = 'Product Registration',
  Approvals = 'Approvals'
}
