import {
  Component,
  OnInit,
  ViewChildren,
  AfterViewInit,
  QueryList,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationError,
  RouterEvent,
  NavigationEnd,
  NavigationCancel
} from '@angular/router';
import {
  ToastaService,
  ToastaConfig,
  ToastOptions,
  ToastData
} from 'ngx-toasta';
import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  AlertService,
  AlertDialog,
  DialogType,
  AlertCommand,
  MessageSeverity
} from './shared/services/alert.service';
import { AppTranslationService } from './shared/services/app-translation.service';
import { LocalStoreManager } from './shared/services/local-store-manager.service';
import { AppTitleService } from './shared/services/app-title.service';
import { AuthService } from './shared/services/auth.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { CompanyService } from './company/services/company.service';
import { LoginComponent } from './employee-profile/components/login/login.component';
import { IdleService } from './shared/services/idle-service.service';
import { SignalRService } from './shared/services/signal-r.service';
import { SidenavService } from './shared/services/sidenav.service';

const alertify: any = require('./assets/scripts/alertify.js');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isAppLoaded: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;

  stickyToasties: number[] = [];

  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;

  loginModal: ModalDirective;
  loginControl: LoginComponent;
  isLoading: boolean = true;

  constructor(
    private idleService: IdleService,
    storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private alertService: AlertService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private companyService: CompanyService,
    public translationService: AppTranslationService,
    public configurations: ConfigurationService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private signalRService: SignalRService,
    private sidenavService: SidenavService
  ) {
    storageManager.initialiseStorageSyncListener();

    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = false;

    this.appTitleService.appName = 'FreePl';
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
    setTimeout(() => (this.isAppLoaded = true), 500);
    setTimeout(() => (this.removePrebootScreen = true), 1000);

    this.alertService
      .getDialogEvent()
      .subscribe(alert => this.showDialog(alert));
    this.alertService
      .getMessageEvent()
      .subscribe(message => this.showToast(message));

    this.authService.reLoginDelegate = () => (this.shouldShowLoginModal = true);

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.authService.gotoDefaultPage();
      }
      if (isLoggedIn && !this.signalRService.isConnectionStarted) {
        this.signalRService.startConnection(
          this.authService?.currentUser?.employeeId
        );
        this.signalRService?.receiveNotification();
      }
      setTimeout(() => {
        if (!isLoggedIn) {
          this.alertService.showMessage(
            'Session Ended!',
            '',
            MessageSeverity.default
          );
        }
      }, 500);
    });

    this.companyService.customerCompanySwitched.subscribe(data => {
      if (data['isNavigateToDashboard'] == true) {
        this.router.navigate([`/company/${data['companyId']}/dashboard`]);
      } else {
        this.alertService.success(
          'Current customer profile changed based on the viewed shipment.'
        );
      }
    });

    this.startWatch();
  }

  ngOnDestroy(): void {
    this.signalRService.stopConnection(
      this.authService?.currentUser?.employeeId
    );
  }

  ngAfterViewInit() {
    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () =>
              this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.isLoading = true;

      if (this.isAdminSidebarExpanded) {
        this.closeAdminSidebar();
      }
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.isLoading = false;
    }
  }

  onLoginModalShown(): void {
    this.alertService.showStickyMessage(
      'Session Expired',
      'Your Session has expired. Please log in again',
      MessageSeverity.info
    );
  }

  onLoginModalHidden(): void {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired) {
      this.alertService.showStickyMessage(
        'Session Expired',
        'Your Session has expired. Please log in again to renew your session',
        MessageSeverity.warn
      );
    }
  }

  onLoginModalHide(): void {
    this.alertService.resetStickyMessage();
  }

  showDialog(dialog: AlertDialog): void {
    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify.confirm(dialog.message, e => {
          if (e) {
            dialog.okCallback();
          } else {
            if (dialog.cancelCallback) {
              dialog.cancelCallback();
            }
          }
        });

        break;
      case DialogType.prompt:
        alertify.prompt(
          dialog.message,
          (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          },
          dialog.defaultValue
        );

        break;
    }
  }

  showToast(alert: AlertCommand): void {
    if (alert?.message?.summary == 'The username/password couple is invalid.') {
      return;
    }

    if (alert.operation === 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail
    };

    if (alert.operation === 'add_sticky') {
      toastOptions.timeout = 0;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 4000;
    }

    switch (alert.message.severity) {
      case MessageSeverity.default:
        this.toastaService.default(toastOptions);
        break;
      case MessageSeverity.info:
        this.toastaService.info(toastOptions);
        break;
      case MessageSeverity.success:
        this.toastaService.success(toastOptions);
        break;
      case MessageSeverity.error:
        this.toastaService.error(toastOptions);
        break;
      case MessageSeverity.warn:
        this.toastaService.warning(toastOptions);
        break;
      case MessageSeverity.wait:
        this.toastaService.wait(toastOptions);
        break;
    }
  }

  startWatch(): void {
    this.idleService.startIdleWatch;
  }

  closeAdminSidebar(): void {
    this.sidenavService.closeSidebar$.emit(true);
  }

  get isUserLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get canSeeAdminSide(): boolean {
    return this.authService.canSeeAdminSide;
  }

  get excludedPages(): boolean {
    const productDetailsPage =
      this.router.url.includes('products-library') &&
      this.router.url.includes('details') &&
      !this.router.url.includes('create-product');
    const registrationDetailsPage =
      this.router.url.includes('registrations') &&
      this.router.url.includes('details') &&
      !this.router.url.includes('create-product-registration');
    const dashboardPage = this.router.url.includes('dashboard');
    const ApprovalDetailsPage =
      this.router.url.includes('approvals') &&
      this.router.url.includes('details') &&
      !this.router.url.includes('create-approval');
    const excludedPages =
      productDetailsPage ||
      registrationDetailsPage ||
      dashboardPage ||
      ApprovalDetailsPage;
    return excludedPages;
  }

  get isAdminSidebarExpanded(): boolean {
    return this.sidenavService.isExpanded;
  }
}
