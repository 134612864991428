import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from 'src/app/company/models/company.model';
import { CompanyUpdateDocumentNote } from 'src/app/shared/models/company-update-document-note';
import { environment } from 'src/environments/environment';
import { TruckingOrCustomsClearanceService } from '../models/trucking-or-customs-clearance-service.model';
import { CompanyService as CompanyServiceModel } from '../../../app/company2/models/company-service.model';
import { ApprovalServicesLookups } from '../models/approval-services-lookups.model';
import { ProductRegistrationServiceDetails } from '../models/product-registration-service-details.model';

@Injectable({ providedIn: 'root' })
export class Company2Service {
  company : Company;
  baseURL = environment.apiUrl + 'companies';

  constructor(private http: HttpClient) {} 

  updateDocumentDescription(
    noteViewModel: CompanyUpdateDocumentNote
  ): Observable<Document> {
    return this.http.post<Document>(
      this.baseURL + `/DocumentNote`,
      noteViewModel
    );
  }

  saveProductRegistrationServiceDetails(
    serviceDetails: ProductRegistrationServiceDetails
  ): Observable<ProductRegistrationServiceDetails> {
    return this.http.post<ProductRegistrationServiceDetails>(
      `${this.baseURL}/ProductRegistrationService`,
      serviceDetails
    );
  }

  updateProductRegistrationServiceDetails(
    serviceDetails: ProductRegistrationServiceDetails
  ): Observable<ProductRegistrationServiceDetails> {
    return this.http.put<ProductRegistrationServiceDetails>(
      `${this.baseURL}/ProductRegistrationService`,
      serviceDetails
    );
  }

  getProductRegistrationServiceDetails(
    id: number
  ): Observable<ProductRegistrationServiceDetails> {
    return this.http.get<ProductRegistrationServiceDetails>(
      `${this.baseURL}/ProductRegistrationService/${id}`
    );
  }

  addEditTruckingOrCustomsClearanceService(
    truckingOrCustomsClearanceService: TruckingOrCustomsClearanceService
  ): Observable<TruckingOrCustomsClearanceService> {
    return this.http.post<TruckingOrCustomsClearanceService>(
      this.baseURL + `/TruckingOrCustomsClearanceService`,
      truckingOrCustomsClearanceService
    );
  }

  getTruckingOrCustomsClearanceService(
    freePlServiceId: number
  ): Observable<TruckingOrCustomsClearanceService> {
    return this.http.get<TruckingOrCustomsClearanceService>(
      this.baseURL + `/TruckingOrCustomsClearanceService/${freePlServiceId}`
    );
  }

  getCompanyService(id: number): Observable<CompanyServiceModel> {
    return this.http.get<CompanyServiceModel>(
      this.baseURL + '/CompanyService?serviceId=' + id
    );
  }

  getApprovalServicesLookups(
    serviceId: number,
    isIncludeCurrecnies: boolean = false
  ): Observable<ApprovalServicesLookups> {
    return this.http.get<ApprovalServicesLookups>(
      this.baseURL +
        `/ApprovalServicesLookups/${serviceId}?isIncludeCurrecnies=${isIncludeCurrecnies}`
    );
  }

  updateCompanyApprovalServices(
    approvalCompanyService: any
  ): Observable<CompanyServiceModel> {
    return this.http.put<any>(
      this.baseURL + `/ApprovalServices`,
      approvalCompanyService
    );
  }

  addCompanyService(
    service: CompanyServiceModel
  ): Observable<CompanyServiceModel> {
    return this.http.post<CompanyServiceModel>(
      this.baseURL + '/CompanyService',
      service
    );
  }

  editCompanyService(
    service: CompanyServiceModel
  ): Observable<CompanyServiceModel> {
    return this.http.put<CompanyServiceModel>(
      this.baseURL + '/CompanyService',
      service
    );
  }

  getApprovalServicesWithCompanyService(
    serviceId: number
  ): Observable<ApprovalServicesLookups> {
    return this.http.get<ApprovalServicesLookups>(
      this.baseURL + `/ApprovalServicesWithCompanyService/${serviceId}`
    );
  }
}
