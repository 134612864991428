import { Component, Input, OnInit } from '@angular/core';
import { Charge } from '../../models/charge.model';

@Component({
  selector: 'app-charges-tab',
  templateUrl: './charges-tab.component.html',
  styleUrls: ['./charges-tab.component.scss']
})
export class ChargesTabComponent implements OnInit {
  @Input() entityName: string;
  @Input() charges: Charge[];

  constructor() {}

  ngOnInit() {}
}
