<div
  class="document-field-container"
  [ngClass]="{ 'blur-container': document == null || isLoading == true }"
>
  <div
    class="row m-0 file-container"
    [ngClass]="{
      'justify-content-between': document?.oldVersions?.length > 0
    }"
  >
    <div class="file">
      <button
        class="file-name cursor-pointer btn-container p-0 border-0"
        (click)="viewFile()"
        [disabled]="document == null"
        [ngbPopover]="fileName"
        [disablePopover]="fileName?.length < 35"
      >
        {{ truncatedFileName }}
      </button>
      <div class="file-upload-date">
        {{ createdAt | fromutc | date: "dd/MM/yyyy h:mm a" }} -
        {{ uploadedBy }},
        {{ companyName }}
      </div>
    </div>
    <div class="actions">
      <button
        class="download-btn action-btn cursor-pointer btn-container p-0 border-0"
        (click)="download()"
        [disabled]="document == null"
      >
        <svg-icon name="download"></svg-icon>
      </button>
      <button
        class="action-btn cursor-pointer btn-container p-0 border-0"
        (click)="replace()"
        [disabled]="document == null || isReplaceButtonhidden"
      >
        <svg-icon name="replaceDocument"></svg-icon>
      </button>
    </div>
    <div
      class="arrow-container ml-4 ms-auto"
      *ngIf="document?.oldVersions?.length > 0"
    >
      <button
        class="action-btn d-flex arrow-btn border-0"
        (click)="panel.toggle()"
        [disabled]="isLoading"
      >
        <svg-icon name="arrow-down"></svg-icon>
      </button>
    </div>
  </div>
  <mat-expansion-panel #panel>
    <app-product-registration-document-older-version
      [oldVersions]="oldVersions"
      [productRegistrationId]="documentParentTypeId"
      [parentDocumentSqlId]="documentSqlId"
    ></app-product-registration-document-older-version>
  </mat-expansion-panel>
</div>
