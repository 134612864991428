<div mat-dialog-content class="wrapper d-flex flex-column overflow-hidden">
  <div class="centered-container d-flex justify-content-center">
    <svg-icon name="shipmentNowActive"></svg-icon>
  </div>

  <div class="centered-container d-flex justify-content-center">
    <span class="title text-center w-100">
      {{ title }}
    </span>
  </div>
  <div *ngIf="isPendingApprovalStatus; else requestedQuoteStatusTemp">
    <div class="centered-container d-flex justify-content-center">
      <span class="description col-9 text-center">
        Your submitted quote proposal is now pending the customer’s approval.
      </span>
    </div>
  </div>
  <ng-template #requestedQuoteStatusTemp>
    <div class="centered-container d-flex justify-content-center">
      <span class="number">Quote No. #{{ quoteNumber }} </span>
    </div>

    <div class="centered-container d-flex justify-content-center">
      <span class="description col-9">
        Your account manager will get back to you with the required quotes
        within
        <span class="font-weight-bolder">2 working days.</span>
      </span>
    </div>
  </ng-template>

  <div class="centered-container d-flex justify-content-center">
    <button
      class="submit-button col-10"
      mat-button
      mat-dialog-close
      (click)="goToQuotes()"
    >
      Go to Quotes
    </button>
  </div>
</div>
