import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notification } from '../models/notification.model';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ShipmentComment } from '../../shipments/models/shipment-comment-model';
import { QuoteComment } from 'src/app/quote/models/quote-comment.model';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: HubConnection;
  public shipmentCommentReceived = new EventEmitter<ShipmentComment>();
  public shipmentCommentUpdateReceived = new EventEmitter<ShipmentComment>();

  quoteCommentReceived = new EventEmitter<QuoteComment>();
  @Output() notificationAdded = new EventEmitter<{
    notification: Notification;
  }>();
  baseURL = environment.baseUrl || 'https://localhost';
  isConnectionStarted: boolean;

  constructor() {}

  public addConnection = (employeeId: string) => {
    this.hubConnection
      .invoke('AddConnection', employeeId)
      .catch(err => console.error(err));
  };

  public startConnection = (employeeId: string) => {
    if (!employeeId || this.isConnectionStarted) {
      return;
    }

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.baseURL}/connectionsHub`)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.isConnectionStarted = true;
        if (employeeId) {
          this.addConnection(employeeId);
        }
      })
      .catch(err => console.log('Error while starting connection: ' + err));
  };

  public receiveNotification = () => {
    // Listen for notifications
    this.hubConnection.on(
      'ReceiveNotification',
      (notification: Notification) => {
        this.notificationAdded.emit({ notification });
      }
    );
  };

  public async removeConnection(employeeId: string) {
    if (this.hubConnection) {
      try {
        return await this.hubConnection.invoke('RemoveConnection', employeeId);
      } catch (err) {
        return console.error(`Error leaving group: ${err}`);
      }
    }
  }

  public stopConnection = (employeeId: string) => {
    if (!employeeId || !this.isConnectionStarted) {
      return;
    }
    this.removeConnection(employeeId).then(() => {
      if (this.hubConnection) {
        this.hubConnection
          .stop()
          .then(() => {
            this.isConnectionStarted = false;
          })
          .catch(err =>
            console.error('Error while stopping SignalR connection: ', err)
          );
      }
    });
  };

  public receiveShipmentComment(): void {
    this.hubConnection.on(
      'ReceiveShipmentMessage',
      (comment: ShipmentComment) => {
        this.shipmentCommentReceived.emit(comment);
      }
    );
  }

  public receiveShipmentCommentUpdate(): void {
    this.hubConnection.on(
      'UpdateShipmentMessage',
      (comment: ShipmentComment) => {
        this.shipmentCommentUpdateReceived.emit(comment);
      }
    );
  }

  receiveQuoteComment(): void {
    this.hubConnection.on('ReceiveQuoteComment', (comment: QuoteComment) => {
      this.quoteCommentReceived.emit(comment);
    });
  }

  closeQuoteCommentConnection(): void {
    this.hubConnection.off('ReceiveQuoteComment');
  }
}
