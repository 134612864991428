import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TruckingPricelistLookups } from '../../../supplier/models/trucking-pricelist-lookups.model';
import { LoadType } from 'src/app/shared/models/load-type.model';
import { TruckType } from 'src/app/shared/models/truck-type.model';
import { TruckingLoadType } from 'src/app/shared/models/enums/trucking-load-type.enum';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { TruckDetails } from '../../../supplier/models/truck-details.model';
import { Currency } from 'src/app/shared/models/currency.model';

@Component({
  selector: 'app-add-edit-truck-details',
  templateUrl: './add-edit-truck-details.component.html',
  styleUrls: ['./add-edit-truck-details.component.scss']
})
export class AddEditTruckDetailsComponent implements OnInit, OnChanges {
  @Input() truckDetailsFormArray: FormArray;
  @Input() lookups: TruckingPricelistLookups;
  @Input() truckDetailsData: TruckDetails[];
  compatibleTruckTypes: TruckType[][] = [];
  @Input() isForCustomerCompany: boolean = false;

  constructor(
    private fb: FormBuilder,
    private formsHelperService: FormsHelperService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    let firstTruckDetails = this.truckDetailsFormArray
      ?.controls[0] as FormGroup;
    let firstTruckDetailsCurrecny = firstTruckDetails?.get('currency')?.value;
    if (this.lookups?.currencies && !firstTruckDetailsCurrecny) {
      this.initFormCurrencies();
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (!this.truckDetailsData || this.truckDetailsData.length <= 0) {
      this.addTruckDetails(true);
    } else {
      this.truckDetailsData?.forEach(element => {
        this.addTruckDetails(false, element);
      });
    }
  }

  addTruckDetails(isNewAdded?: boolean, truckDetails?: TruckDetails) {
    let truckDetailsWithSelectedLookup = this.getSeletedLookups(truckDetails);
    const truckDetailsForm = this.fb.group({
      loadType: [truckDetailsWithSelectedLookup?.loadType, Validators.required],
      truckType: [
        truckDetailsWithSelectedLookup?.truckType,
        Validators.required
      ],
      truckSubType: [
        truckDetailsWithSelectedLookup?.truckSubType,
        Validators.required
      ],
      transportPrice: [
        truckDetails?.transportPrice,
        !this.isForCustomerCompany ? Validators.required : ''
      ],
      currency: [
        truckDetailsWithSelectedLookup?.currency || this.egpCurrency,
        !this.isForCustomerCompany ? Validators.required : ''
      ],
      isDeleted: [truckDetails?.isDeleted],
      createdAt: [truckDetails?.createdAt],
      isNewAdded: [isNewAdded]
    });

    if (truckDetailsWithSelectedLookup) {
      this.disableFieldsInEditMode(truckDetailsForm);
    }

    this.truckDetailsFormArray.push(truckDetailsForm);
  }

  disableFieldsInEditMode(truckDetailsForm) {
    truckDetailsForm?.get('loadType').disable();
    truckDetailsForm?.get('truckType').disable();
    truckDetailsForm?.get('truckSubType').disable();
    truckDetailsForm.updateValueAndValidity();
  }

  getSeletedLookups(truckDetails?: TruckDetails) {
    if (!truckDetails) {
      return;
    }

    let truckDetailsWithSelectedLookup = {
      loadType: null,
      currency: null,
      truckSubType: null,
      truckType: null
    };

    if (truckDetails?.loadTypeId) {
      truckDetailsWithSelectedLookup.loadType = {
        name: truckDetails?.loadType,
        id: truckDetails?.loadTypeId
      };
    }
    if (truckDetails?.currencyCode) {
      truckDetailsWithSelectedLookup.currency = {
        code: truckDetails?.currencyCode,
        id: truckDetails?.currencyId
      };
    }
    if (truckDetails?.truckSubTypeId) {
      truckDetailsWithSelectedLookup.truckSubType = {
        name: truckDetails?.truckSubType,
        id: truckDetails?.truckSubTypeId
      };
    }
    if (truckDetails?.truckTypeId) {
      truckDetailsWithSelectedLookup.truckType = {
        name: truckDetails?.truckType,
        id: truckDetails?.truckTypeId
      };
    }

    return truckDetailsWithSelectedLookup;
  }

  deleteTruckDetails(index: number) {
    let isNewAdded = this.truckDetailsFormArray.controls[index]?.get(
      'isNewAdded'
    )?.value;

    if (isNewAdded) {
      this.truckDetailsFormArray?.removeAt(index);
      return;
    }
    this.truckDetailsFormArray.controls[index]
      ?.get('isDeleted')
      ?.setValue(true);
  }

  onChangeLoadType(loadType: LoadType, index: number) {
    this.addOrClearValidatorsTruckAndSupTruckType(index);
    this.filterTruckTypes(loadType?.id, index);

    let truckTypeId = this.truckDetailsFormArray.controls[index]?.get(
      'truckType'
    )?.value?.id;

    let isLoadTypeIncludedInOldTruckType = this.lookups?.truckTypes.some(
      item =>
        item.id === truckTypeId &&
        item.loadTypes.some(x => x.id == loadType?.id)
    );

    if (!isLoadTypeIncludedInOldTruckType || this.isLTLLoadType(index)) {
      this.deleteTruckTypeValue(index);
    }
  }

  deleteTruckTypeValue(index: number) {
    this.truckDetailsFormArray.controls[index]
      ?.get('truckType')
      ?.setValue(null);
  }

  addOrClearValidatorsTruckAndSupTruckType(index: number) {
    let truckDetailsFormGroup = this.truckDetailsFormArray?.controls[
      index
    ] as FormGroup;

    if (this.isLTLLoadType(index)) {
      this.clearValidator(truckDetailsFormGroup, 'truckType');
      truckDetailsFormGroup?.get('truckType')?.disable();
      this.clearValidator(truckDetailsFormGroup, 'truckSubType');
      truckDetailsFormGroup?.get('truckSubType')?.disable();
      return;
    }
    this.addRequiredValidator(truckDetailsFormGroup, 'truckType');
    truckDetailsFormGroup?.get('truckType')?.enable();
    this.addRequiredValidator(truckDetailsFormGroup, 'truckSubType');
    truckDetailsFormGroup?.get('truckSubType')?.enable();
  }

  filterTruckTypes(loadTypeId: number, index: number) {
    if (this.compatibleTruckTypes?.length < index) {
      this.compatibleTruckTypes.push([]);
    }

    if (this.isLTLLoadType(index)) {
      return;
    }

    this.compatibleTruckTypes[index] = this.lookups?.truckTypes?.filter(item =>
      item.loadTypes.some(x => x.id == loadTypeId)
    );
  }

  isLTLLoadType(index): boolean {
    return (
      this.truckDetailsFormArray?.controls[index]?.get('loadType')?.value
        ?.name == TruckingLoadType.LTL
    );
  }

  get isEnableDeleteRoute(): boolean {
    let count = 0;
    this.truckDetailsFormArray?.controls.forEach(control => {
      let isDeleted = control.get('isDeleted')?.value;
      if (!isDeleted) {
        count++;
      }
    });
    return count > 1;
  }

  addRequiredValidator(formGroup: FormGroup, controlName: string) {
    this.formsHelperService?.addRequiredValidator(formGroup, controlName);
  }

  clearValidator(formGroup: FormGroup, controlName: string) {
    this.formsHelperService?.clearValidator(formGroup, controlName);
  }

  isInvalid(formControl: string, index: number): boolean {
    return (
      this.truckDetailsFormArray?.controls[index]?.get(formControl)?.errors &&
      this.truckDetailsFormArray?.controls[index]?.get(formControl)?.touched
    );
  }

  get egpCurrency(): Currency {
    let egpCurrency = this.lookups?.currencies?.find(c => c.code === 'EGP');
    return egpCurrency;
  }

  initFormCurrencies() {
    this.truckDetailsFormArray?.controls?.forEach((formGroup: FormGroup) => {
      let currency = formGroup?.get('currency')?.value;
      if (!currency) {
        formGroup?.get('currency')?.setValue(this.egpCurrency);
      }
    });
  }
}
