<div
  class="top-bar-container d-flex align-items-baseline justify-content-between"
>
  <div
    class="followed-status-container d-flex justify-content-center align-items-center"
  >
    <button
      class="back"
      [routerLink]="['../..']"
      [ngClass]="{ 'product-no': isProductLibraryModule }"
    >
      <svg-icon name="arrowToLeft" class="back-icon"></svg-icon>
      <span>
        {{ backLinkText }}
      </span>
    </button>

    <div class="status-container" *ngIf="!canManageStatus">
      <div
        class="status d-flex justify-content-evenly align-items-center"
        [style.color]="currentColorStatus"
      >
        <span
          class="pr-1 d-flex status-title overflow-hidden text-nowrap justify-content-center"
          [ngClass]="{ 'with-icon': isCancelledStatus || isProductPaused }"
        >
          {{
            isRegistrationModule && canManageRegistrationStatus
              ? activityName
              : statusName
          }}
        </span>

        <span *ngIf="isCancelledStatus" class="info-icon-cancel">
          <svg-icon
            name="red-info"
            [ngbPopover]="infoTemplateCancelled"
            placement="bottom-left"
            [autoClose]="'outside'"
            triggers="mouseenter:mouseleave"
            class="info-icon"
          ></svg-icon>
        </span>

        <span *ngIf="isProductPaused && isRegistrationModule">
          <svg-icon
            name="info"
            [ngbPopover]="infoTemplatePaused"
            placement="bottom-left"
            [autoClose]="'outside'"
            triggers="mouseenter:mouseleave"
            class="info-icon"
          ></svg-icon>
        </span>

        <ng-template #infoTemplateCancelled>
          <div class="reason-template">
            <div class="row p-0">
              <div class="col-5 pop-over-label p-0">{{ statusName }} on</div>
              <div class="col-7 pop-over-field p-0">
                {{
                  cancelationDetails?.canceledAt | date: "dd/MM/YYYY hh:mm a"
                }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">{{ statusName }} by</div>
              <div class="col-7 pop-over-field p-0">
                {{ cancelationDetails.canceledBy?.fullName }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">Reason</div>
              <div class="col-7 pop-over-field p-0">
                {{ cancelationDetails.reason }}
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #infoTemplatePaused>
          <div class="reason-template">
            <div class="row p-0">
              <div class="col-5 pop-over-label p-0">Paused on</div>
              <div class="col-7 pop-over-field p-0">
                {{ pauseDetails?.pausedAt | date: "dd/MM/YYYY hh:mm a" }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">Paused by</div>
              <div class="col-7 pop-over-field p-0">
                {{ pauseDetails?.pausedBy?.fullName }}
              </div>
            </div>
            <div class="row mt-2 p-0">
              <div class="col-5 pop-over-label p-0">Reason</div>
              <div class="col-7 pop-over-field p-0">
                {{ pauseDetails?.reason }}
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="status-btn"
      *ngIf="canManageStatus"
      ngbDropdown
      #dropdown="ngbDropdown"
      [placement]="'bottom-right'"
    >
      <div
        class="status-dropdown row cursor-pointer align-items-center"
        ngbDropdownToggle
      >
        <span
          class="status-dropdown-wrd text-nowrap justify-content-center overflow-hidden"
          [ngClass]="{ 'd-block': isLagrWord }"
        >
          {{ activityName }}
        </span>
        <svg-icon
          [ngClass]="{ show: isShown }"
          name="black-arrow-down"
          class="black-arrow-down col-1"
        ></svg-icon>
      </div>
      <div class="dropdown position-absolute overflow-hidden" ngbDropdownMenu>
        <div class="dropdown-content d-flex flex-column">
          <p class="dropdown-content-title mb-0">
            {{ isProductLibraryModule ? "Status" : " Post Activity" }}
          </p>
          <mat-radio-group [(ngModel)]="selectedActivityValue">
            <mat-radio-button
              *ngFor="let activity of nextActivities"
              [value]="activity.id"
              [style.color]="activity.colorCode"
              (click)="sendClickedValueName(activity.name)"
            >
              {{ activity.name }}
              <div *ngIf="hasInputField">
                <input
                  type="text"
                  *ngIf="selectedActivityValueName == activity.name"
                  [placeholder]="inputFieldPlaceholder"
                  [(ngModel)]="inputActivityValue"
                />
              </div>
            </mat-radio-button>
          </mat-radio-group>
          <div class="button-container d-flex justify-content-center">
            <button
              (click)="
                hasInputField && isUploadLicense
                  ? uploadLicense()
                  : updateActivity()
              "
              class="btn d-flex activity-btn justify-content-center align-items-center"
              [disabled]="isDisabledActionBtn || isSaving"
            >
              <p class="activity-btn-title m-0">
                {{ activityButtonText }}
              </p>
              <i
                *ngIf="isSaving"
                class="fa fa-circle-o-notch fa-spin activity-btn-spin"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="followed-by-img cursor-pointer d-flex justify-content-center align-items-center overflow-hidden"
      [ngbPopover]="fullNameTemplate"
      placement="bottom-right"
      [autoClose]="'outside'"
      triggers="mouseenter:mouseleave"
    >
      <img
        [src]="profilePictureUrl"
        *ngIf="profilePictureUrl"
        alt=""
        class="profile-picture"
      />
      <div class="default-actor-picture" *ngIf="!profilePictureUrl">
        {{ createdBy | userNameInitials }}
      </div>
      <ng-template #fullNameTemplate>
        <div>
          {{ createdBy }}
        </div>
      </ng-template>
    </div>
  </div>

  <div class="actions-countainer d-flex align-items-center">
    <button
      class="resume-paused-btn border-0 bg-transparent m-0 h-0 text-left"
      (click)="onPauseResume()"
      *ngIf="canPauseResumeProductRegistration && isRegistrationModule"
      [disabled]="isCancelledStatus"
    >
      <div
        [class]="isProductPaused ? 'resume-request' : 'pause-request text-left'"
      >
        <i
          [class]="
            isProductPaused
              ? 'fa fa-play-circle resume-shipment-icon'
              : 'fa fa-pause-circle-o hold-shipment-icon'
          "
          aria-hidden="true"
        ></i>
        {{ pausedResumeBtnTxt }}
      </div>
    </button>

    <button
      class="cancel-btn border-0 bg-transparent m-0"
      [ngClass]="{
        'disabled-cancel-btn':
          (!isNewSystemStatus && isRegistrationModule) ||
          (isApprovalModule && !canCancelApproval)
      }"
      (click)="onDelete()"
      [disabled]="
        (!isNewSystemStatus && isRegistrationModule) ||
        (isApprovalModule && !canCancelApproval)
      "
      *ngIf="
        (isRegistrationModule && canCancelProductRegistration) ||
        (isProductLibraryModule && canDeleteProduct) ||
        isApprovalModule
      "
    >
      <div class="cancel-icon d-flex">
        <svg-icon
          [name]="
            (!isNewSystemStatus && isRegistrationModule) ||
            (isApprovalModule && !canCancelApproval)
              ? 'gray-cancel-icon'
              : 'cancelShipment'
          "
        ></svg-icon>
        <span
          [ngClass]="{
            'disabled-cancel-btn text-left':
              (!isNewSystemStatus && isRegistrationModule) ||
              (isApprovalModule && !canCancelApproval),
            cancelShipment: isProductLibraryModule
          }"
        >
          {{ deleteBtnText }}
        </span>
      </div>
    </button>
  </div>
</div>
