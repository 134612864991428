import { Component, Input, OnInit } from '@angular/core';
import { DeleteDocumentComponent } from 'src/app/shared/components/delete-document/delete-document.component';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ProductDocument } from 'src/app/shared/models/product-document.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-product-registration-document-older-version',
  templateUrl: './product-registration-document-older-version.component.html',
  styleUrls: ['./product-registration-document-older-version.component.scss']
})
export class ProductRegistrationDocumentOlderVersionComponent
  implements OnInit {
  @Input() oldVersions: ProductDocument[];
  @Input() productRegistrationId: string;
  @Input() parentDocumentSqlId: number;

  constructor(
    private alertService: AlertService,
    private fileViewerService: FileViewerService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  deleteDocument(version: ProductDocument) {
    if (!version.isDeleted) {
      this.dialogService.open(
        DeleteDocumentComponent,
        {
          parentId: this.productRegistrationId,
          module: ModuleName.Registration,
          documentSqlId: version.documentSqlId,
          parentDocumentSqlId: this.parentDocumentSqlId,
          documentTypeName: version.documentTypeName
        },
        '600px',
        '650px'
      );
    }
  }

  download(version: ProductDocument) {
    if (!version.isDeleted) {
      var link = document.createElement('a');
      link.setAttribute('download', version?.fileName);
      link.setAttribute('href', version?.path);

      document.body.appendChild(link);
      link.click();
      link.remove();
      this.alertService.success('Document successfully downloading.');
    }
  }

  viewFile(version: ProductDocument): void {
    if (!version?.isDeleted) {
      this.fileViewerService.view(version?.path);
    }
  }
}
