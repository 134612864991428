<div class="summary-container">
  <div *ngIf="isInOverview">
    <div class="row justify-content-between">
      <div class="section-title">Registration Information</div>
      <div class="col-10">
        <div class="row column-gap">
          <div class="col-4 title">Processing Type</div>
        </div>
        <div class="row column-gap space-between-rows">
          <div class="col-4 product-data">
            {{ processingType }}
          </div>
        </div>
        <div class="row column-gap">
          <div class="col-4 title">Acceptance Number</div>
          <div class="col-4 title">Registration Number</div>
          <div class="col-4 title">Registration Licence</div>
        </div>
        <div class="row column-gap space-between-rows">
          <div class="col-4 product-data">
            {{ productRegistration?.acceptanceNumber || "-" }}
          </div>
          <div class="col-4 product-data">
            {{ productRegistration?.registrationLicenseNumber || "-" }}
          </div>
          <div class="col-4 product-data">
            <a
              *ngIf="isFileExist(true)"
              (click)="download(true)"
              class="link text-underline cursor-pointer"
              [ngbPopover]="getFileName(true)"
              [disablePopover]="getFileName(true)?.length < 33"
              triggers="mouseenter:mouseleave"
            >
              {{ getFileName(true) | slice: 0:33 }}
              <span *ngIf="getFileName(true)?.length >= 33">...</span>
            </a>
            <span *ngIf="!isFileExist(true)">No document uploaded yet.</span>
          </div>
        </div>
        <div class="row column-gap">
          <div class="col-4 title">Created By</div>
          <div class="col-4 title">Created On</div>
          <div class="col-4 title">Technical File</div>
        </div>
        <div class="row column-gap space-between-rows">
          <div class="col-4 product-data">
            {{ productRegistration?.createdBy.fullName || "-" }}
          </div>
          <div class="col-4 product-data">
            {{ productRegistration?.submittedAt | date: "dd/MM/YYYY" || "-" }}
          </div>
          <div class="col-4 product-data">
            <a
              *ngIf="isFileExist(false)"
              (click)="download(false)"
              class="link text-underline cursor-pointer"
              [ngbPopover]="getFileName(false)"
              [disablePopover]="getFileName(false)?.length < 33"
              triggers="mouseenter:mouseleave"
            >
              {{ getFileName(false) | slice: 0:33
              }}<span *ngIf="getFileName(false)?.length >= 33">...</span>
            </a>
            <span *ngIf="!isFileExist(false)">No document uploaded yet.</span>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div class="row justify-content-between mx-0">
    <div class="section-title">Product Information</div>
    <div class="col-10">
      <div class="row column-gap">
        <div class="col-4 title">Category</div>
        <div class="col-4 title">Product Grouping</div>
        <div
          class="col-4 title"
          *ngIf="isInOverview && isSingleProductGrouping"
        >
          Part Number
        </div>
      </div>
      <div class="row column-gap space-between-rows">
        <div class="col-4 product-data">
          {{ productRegistration?.category || "-" }}
        </div>
        <div class="col-4 product-data">
          {{ productRegistration?.productGrouping || "-" }}
        </div>
        <div
          class="col-4 product-data"
          *ngIf="isInOverview && isSingleProductGrouping"
        >
          {{ singleSelectedArticleNumber || "-" }}
        </div>
      </div>
      <div *ngIf="isSingleProductGrouping">
        <div class="row column-gap">
          <div class="col-4 title" *ngIf="!isInOverview">
            Part Number
          </div>
          <div class="col-4 title" *ngIf="!isInOverview">
            Product Description
          </div>
        </div>
        <div class="row column-gap">
          <div class="col-4 product-data" *ngIf="!isInOverview">
            {{ singleSelectedArticleNumber || "-" }}
          </div>
          <div class="col-4 product-data" *ngIf="!isInOverview">
            {{ singleSelectedProductName || "-" }}
          </div>
        </div>
      </div>
      <div
        class="row-margin"
        *ngIf="isBundleProductGrouping || isSystemProductGrouping"
      >
        <div class="row column-gap">
          <div class="col-4 title">
            {{ isBundleProductGrouping ? "Bundle Name" : "System Name" }}
          </div>
        </div>
        <div class="row column-gap space-between-rows">
          <div class="col-4 product-data">
            {{ productGroupsName || "-" }}
          </div>
        </div>
        <div class="row column-gap">
          <div class="col-4 product-data">
            {{
              isBundleProductGrouping
                ? "Bundle Components"
                : "System Components"
            }}
            <div class="row-margin"></div>
          </div>
        </div>

        <div class="row column-gap">
          <div class="col-3 title">Product</div>
          <div class="col-3 title">Part Number</div>
          <div class="col-3 title" *ngIf="!isInOverview">
            Product Description
          </div>
        </div>

        <div
          class="row column-gap"
          *ngFor="let product of productsDetails; let i = index"
        >
          <div class="col-3 product-data">
            {{ i + 1 }}
          </div>
          <div class="col-3 product-data">
            {{ product?.articleNumber || "-" }}
          </div>
          <div class="col-3 product-data" *ngIf="!isInOverview">
            {{ product?.productName || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
