import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EntityType } from '../../../createShipment/models/enums/entity-type.enum';

@Component({
  selector: 'app-submit-popup',
  templateUrl: './submit-popup.component.html',
  styleUrls: ['./submit-popup.component.scss']
})
export class SubmitPopupComponent implements OnInit {
  submissionNumberString?: string;
  moduleName?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private authService: AuthService
  ) {
    this.submissionNumberString = data['submissionNumberString'];
    this.moduleName = data['moduleName'];
  }

  ngOnInit() {}

  OnSubmissionRouting(): void {
    if (this.moduleName == EntityType.Warehousing) {
      this.router.navigate([
        'company/' + this.defaultCompanyId + `/${this.moduleName.toLowerCase()}`
      ]);
    } else {
      this.router.navigate([
        'company/' +
          this.defaultCompanyId +
          `/${this.moduleName.toLowerCase()}s`
      ]);
    }
  }

  get goToButtonTitle(): string {
    if (this.moduleName == EntityType.Warehousing) {
      return `Go to ${this.moduleName}`;
    }
    return `Go to ${this.moduleName}s`;
  }
  get defaultCompanyId(): string {
    return this.authService?.currentUser?.defaultCustomerCompany;
  }
}
