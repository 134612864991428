<div class="clearance-details-container" [formGroup]="form">
  <div class="flex-row">
    <div class="field" *ngIf="hasNameField">
      <div class="text-label">Supplier<span class="required">*</span></div>
      <input
        type="text"
        formControlName="supplier"
        class="form-control"
        title="Supplier"
        [readonly]="isEditRoute"
        placeholder="Enter supplier"
      />
    </div>

    <div class="field">
      <div class="text-label">
        Transaction Type<span class="required">*</span>
      </div>
      <ng-select
        formControlName="transactionTypes"
        [items]="transactionTypes"
        [multiple]="true"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="false"
        [readonly]="isEditRoute"
        bindValue="item"
        bindLabel="item"
        [ngClass]="{
          error: isInvalidFormControl('transactionTypes')
        }"
      >
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          {{ items.join(", ") }}
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div class="d-flex align-items-center">
            <app-checkbox
              id="item-{{ index }}"
              [checked]="item$.selected"
            ></app-checkbox>
            <span class="mx-1"></span>
            <span class="title">
              {{ item }}
            </span>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="field">
      <div class="text-label">Freight Type<span class="required">*</span></div>
      <ng-select
        formControlName="freightType"
        [items]="freightTypes"
        [multiple]="false"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="true"
        [readonly]="isEditRoute"
        bindValue="item"
        bindLabel="item"
        (change)="changeFreightType()"
        [ngClass]="{
          error: isInvalidFormControl('freightType')
        }"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-item$="item$"
        >
          <div class="d-flex align-items-center">
            <mat-radio-button [value]="item" [checked]="item$.selected">
            </mat-radio-button>
            <span class="mx-1"></span>
            <span class="title">
              {{ item }}
            </span>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="field field-space-between">
      <div>
        <div class="text-label">
          Port of Clearance<span class="required">*</span>
        </div>
        <ng-select
          [typeahead]="ports$"
          formControlName="portsOfClearance"
          [items]="portsObservable | async"
          [multiple]="true"
          [clearable]="false"
          [searchable]="true"
          [closeOnSelect]="false"
          [readonly]="isDisabledPortOfClearance"
          [bindLabel]="'code'"
          [ngClass]="{
            error: isInvalidFormControl('portsOfClearance')
          }"
          [readonly]="isEditRoute"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div class="ng-value-label hsCodes-label pb-1 pl-2">
              {{ item.code }}
              <svg-icon
                name="close-icon"
                (click)="clear(item)"
                *ngIf="!isEditRoute"
              ></svg-icon>
            </div>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <div class="d-flex align-items-center">
              <app-checkbox
                id="item-{{ index }}"
                [checked]="item$.selected"
              ></app-checkbox>
              <span class="mx-1"></span>
              <span class="title">
                {{ item.name }}, {{ item.cityName }}, ({{ item.code }})
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <mat-checkbox
        id="selectAllPorts"
        formControlName="isAllPortsSelected"
        [checked]="isAllPortSelected"
        (change)="selectOrDeselectAllPorts($event)"
        *ngIf="isShowAllPortsCheckbox"
        [disabled]="isEditRoute"
      >
        <label for="selectAllPorts">Select all ports</label>
      </mat-checkbox>
    </div>

    <div class="field" *ngIf="!isAirFreight">
      <div class="text-label">Load Type<span class="required">*</span></div>
      <ng-select
        formControlName="loadTypes"
        [items]="loadTypes"
        [multiple]="false"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="true"
        [readonly]="isEditRoute"
        bindLabel="name"
        (change)="onChangeLoadType('loadTypes')"
        [ngClass]="{
          error: isInvalidFormControl('loadTypes')
        }"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-item$="item$"
        >
          <div class="d-flex align-items-center">
            <mat-radio-button [value]="item" [checked]="item$.selected">
            </mat-radio-button>
            <span class="mx-1"></span>
            <span class="title">
              {{ item?.name }}
            </span>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="field" *ngIf="isLCLLoadType || isFCLLoadType">
      <div class="text-label">
        Container Type<span class="required">*</span>
      </div>
      <ng-select
        formControlName="containerTypes"
        [items]="containerTypes"
        [multiple]="true"
        [clearable]="false"
        [searchable]="false"
        [closeOnSelect]="false"
        [bindLabel]="'name'"
        [ngClass]="{
          error: isInvalidFormControl('containerTypes')
        }"
      >
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          {{ selectedContainerTypes(items) }}
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div class="d-flex align-items-center">
            <app-checkbox
              id="item-{{ index }}"
              [checked]="item$.selected"
            ></app-checkbox>
            <span class="mx-1"></span>
            <span class="title">
              {{ item?.name }}
            </span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
